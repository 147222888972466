import React, { useEffect, useState } from "react";
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import logo from "../../assets/images/astroLogo.png";
import axios from "axios";
import { BASE_URL } from "../../apiContstants";

function InvoiceBill() {
  const [invoiceData, setInvoiceData] = useState(null);

  const loginUser = JSON.parse(localStorage.getItem("loginUser")) || [];
  const userProfileId =
    Array.isArray(loginUser) && loginUser.length > 0
      ? loginUser[0].userProfileId
      : loginUser?.userProfileId || "";

  const fetchInvoiceData = async () => {
    if (!userProfileId) return;
    // console.log("debug", userProfileId);
    const response = await axios.get(
      `${BASE_URL}api/shiprocket/getInvoice/${userProfileId}`
    );
    // console.log(response.data);
    setInvoiceData(response.data);
  };

  useEffect(() => {
    fetchInvoiceData();
  }, []);

  const styles = {
    page: {
      padding: 30,
      paddingTop: 30,
      fontFamily: "Helvetica",
    },
    header: {
      fontSize: 20,
      marginBottom: 20,
      textAlign: "center",
      color: "#4F46E5",
    },
    section: {
      paddingTop: 15,
      marginBottom: 15,
    },
    table: {
      width: "100%",
      borderWidth: 1,
      borderColor: "#ddd",
      borderRadius: 5,
      overflow: "hidden",
    },
    tableRow: {
      flexDirection: "row",
      paddingVertical: 10,
      borderBottomWidth: 1,
      borderBottomColor: "#ddd",
      justifyContent: "space-between",
    },
    tableCellHeader: {
      flex: 1,
      paddingHorizontal: 8,
      textAlign: "center",
      fontWeight: "semi-bold",
      backgroundColor: "#f0f0f0",
    },
    tableCell: {
      flex: 1,
      paddingHorizontal: 8,
      textAlign: "center",
      fontSize: 12,
    },
    text: {
      fontSize: 12,
      marginBottom: 5,
    },
    orderText: {
      fontSize: 12,
      marginBottom: 15,
    },
    subHeader: {
      fontSize: 15,
      marginBottom: 5,
    },
    totalSection: {
      marginTop: 20,
      textAlign: "right",
      fontSize: 14,
      fontWeight: "bold",
    },
    horizontalLine: {
      borderBottomWidth: 1,
      borderBottomColor: "#ddd",
      marginVertical: 10,
    },
    logo: {
      position: "absolute",
      top: 0,
      left: 20,
      width: 100,
      height: 100,
    },
  };

  const finalAmount =
  ((invoiceData && invoiceData.planAmount) || 0) +
  (Math.round((invoiceData && invoiceData.frightCharges)) || 0);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.logo} src={logo} />

        <Text style={styles.header}>
          Invoice for Order {(invoiceData && invoiceData.uuId) || "N/A"}
        </Text>

        <View style={styles.section}>
          <Text style={styles.text}>
            Invoice Number: {(invoiceData && invoiceData.uuId) || "N/A"}
          </Text>
          <Text style={styles.text}>
            Order Date: {(invoiceData && invoiceData.orderDate) || "N/A"}
          </Text>
          <Text style={styles.text}>
            Customer Name:{" "}
            {`${(invoiceData && invoiceData.shipping_customer_name) || ""} ${
              (invoiceData && invoiceData.shipping_last_name) || ""
            }`}
          </Text>
          <Text style={styles.text}>Order Total: {finalAmount || "N/A"}</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.horizontalLine}></View>

          {/* <View style={styles.horizontalLine}></View>
          <Text style={styles.header}>Dispatched on 5th October</Text>
          <View style={styles.addressSection}>
            <Text style={styles.subHeader}>Items Ordered:</Text>
            <Text style={styles.orderText}>Bharat AstroCard NFC designed for mobile use with lifetime unlimited validity.</Text>
          </View> */}

          <View style={styles.addressSection}>
            <Text style={styles.subHeader}>Items Ordered:</Text>
            <Text style={styles.orderText}>
              Bharat AstroCard NFC designed for mobile use with lifetime
              unlimited validity.
            </Text>
          </View>
          <View style={styles.addressSection}>
            <Text style={styles.addressHeader}>Delivery Address:</Text>
            <Text style={styles.text}>
              {`${(invoiceData && invoiceData.shipping_customer_name) || ""} ${
                (invoiceData && invoiceData.shipping_last_name) || ""
              }`}
            </Text>
            <Text style={styles.text}>
              {(invoiceData && invoiceData.shipping_address) || "N/A"}
            </Text>
            <Text style={styles.text}>
              {(invoiceData && invoiceData.shipping_city) || "N/A"},{" "}
            </Text>
            <Text style={styles.text}>
              {(invoiceData && invoiceData.shipping_state) || "N/A"},{" "}
              {(invoiceData && invoiceData.shipping_pincode) || "N/A"}{" "}
            </Text>
            <Text style={styles.text}>
              {(invoiceData && invoiceData.shipping_country) || "N/A"}
            </Text>
          </View>
        </View>
        <View>
          <Text style={styles.subHeader}>Delivery Option:</Text>
          <Text style={styles.text}>Standard Delivery</Text>
        </View>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableCellHeader}>Item</Text>
            <Text style={styles.tableCellHeader}>Quantity</Text>
            <Text style={styles.tableCellHeader}>Price</Text>
            <Text style={styles.tableCellHeader}>Shipping charge</Text>
            <Text style={styles.tableCellHeader}>Total</Text>
          </View>
          {/* {(invoiceData && invoiceData?.items || []).map((item, index) => ( */}
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Astro Card</Text>
            <Text style={styles.tableCell}>1</Text>
            <Text style={styles.tableCell}>
              {(invoiceData && invoiceData.planAmount) || 0} /-
            </Text>
            <Text style={styles.tableCell}>
              {(invoiceData && invoiceData.frightCharges) || 0} /-
            </Text>
            <Text style={styles.tableCell}>{finalAmount || 0} /-</Text>
          </View>
          {/* ))} */}
        </View>
        <View style={styles.totalSection}>
          <Text>Grand Total: {finalAmount || "N/A"} /-</Text>
        </View>

        {/* <View>
          <Text style={styles.subHeader}>Payment Method:</Text>
          <Text style={styles.text}>eg:- Amazon Pay Later</Text>
        </View> */}
      </Page>
    </Document>
  );
}

export default InvoiceBill;
