import React, { useState, useEffect } from "react";
import LOGO from "../assets/images/logo.png";
import LOGO_ICON from "../assets/images/login icon.png";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "./AuthProvider";
import loader from "../assets/images/loader1.gif"

function Login() {
  const [login, setLogin] = useState({
    email: "",
    password: "",
    error: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { loginMethod: authenticate, loginUser, error } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    // if (Array.isArray(loginUser) && loginUser.length > 0) {
    //   if (loginUser[0].status === "Success" && !loginUser[0].firstName) {
    //     navigate("/customerData");
    //   } else {
    //     navigate("/");
    //   }
    // }
    if (Array.isArray(loginUser) && loginUser.length > 0) {
      // if (loginUser[0].status === "Success" && loginUser[0].planType !== "Web" && loginUser[0].billing_customer_name === null) {
      //   navigate("/shipadd");
      // } else
      if (loginUser[0].status === "Success" && !loginUser[0].firstName) {
        navigate("/customerData");
      } else {
        navigate("/");
      }
    }
  }, [loginUser, navigate]);  

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLogin({
      ...login,
      [name]: value,
      error: "",
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true)
    const passwordValidation = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!login.email || !login.password) {
      setLogin({
        ...login,
        error: "All fields are mandatory",
      });
      return;
    }
    if (!passwordValidation.test(login.password)) {
      setLogin({
        ...login,
        error: "Password must be at least 8 characters, include at least 1 uppercase, 1 lowercase, 1 number, and 1 special character",
      });
      return;
    }

    const { email, password } = login;
    const loginData = { email, password };

    try {
      const response = await authenticate(loginData);

      if (!response) {
        setLogin({
          ...login,
          error: error,
        });
      }
    } catch (err) {
      setLogin({
        ...login,
        error: "Login failed",
      });
    }finally {
      setLoading(false)
    }
  };

  return (
    <div className="bg-img h-screen bg-cover bg-center">
      <div
        className="flex flex-row justify-between w-full md:h-24 h-16 items-center px-4 lg:px-24 md:px-18"
        style={{ background: "#1b1919" }}
      >
        <div className="flex justify-center md:justify-start">
          <Link to="/">
            <img src={LOGO} className="h-16 md:h-36" alt="logo" />
          </Link>
        </div>
        <div className="flex flex-row md:gap-24 gap-4 pr-26 md:text-xl text-sm">
          <Link
            to="/"
            className="Poppins text-white cursor-pointer"
          >
            Home
          </Link>
          <Link
            to="/login"
            className="Poppins text-white cursor-pointer"
          >
            Login
          </Link>
          <Link
            to="/signup"
            className="Poppins text-white cursor-pointer"
          >
            Signup
          </Link>
        </div>
      </div>
      <div className="flex items-center md:h-3/4 justify-center md:mt-0 mt-28">
        <div className="bg-white xl:w-1/4 lg:w-[40%] md:w-2/3 md:mx-0 mx-4 py-6 rounded-2xl relative">
          <img
            src={LOGO_ICON}
            alt="Your Image"
            className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:w-24 md:h-24 h-20 w-20 object-cover rounded-full"
          />
          <h1 className="md:text-2xl text-1xl text-center font-bold arial px-9 tracking-tighter md:mt-16 mt-11">
            Login
          </h1>
          {
            loading ? 
            <img src={loader} alt="loader" /> 
            :
            
          <form onSubmit={handleLogin}>
          <div className="flex justify-center mt-6 px-12">
            <input
              type="email"
              className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none"
              placeholder="Enter email"
              name="email"
              value={login.email}
              onChange={handleChange}
            />
          </div>
          <div className="relative flex justify-center mt-4 px-12">
            <input
              type={showPassword ? "text" : "password"}
              className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none"
              placeholder="Enter password"
              name="password"
              value={login.password}
              onChange={handleChange}
            />
            <button
              type="button"
              className="absolute right-12 p-2 top-1/2 transform -translate-y-1/2"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? "👁️" : "👁️"}
            </button>
          </div>
          {(error) && (
            <p className="text-red-500 text-center mt-4 px-2">
              {error}
            </p>
          )}
          <p className="text-center mt-4">
            <Link to="/forgotPassword" className="text-blue-500 hover:underline">Forgot Password?</Link>
          </p>
          <div className="flex justify-center px-12 my-4">
            <button
              type="submit"
              style={{ background: "#000134", fontSize: "15px" }}
              className="w-full rounded-md py-2 text-white font-medium Poppins"
            >
              Login
            </button>
          </div>
          <div className="text-center">
            Doesn't have an account? 
            <Link to="/signup" className="text-blue-500 underline"> Signup</Link>
          </div>
        </form>
          }
        </div>
      </div>
    </div>
  );
}

export default Login;
