import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { useAuth } from "../../auth/AuthContext";

const Header = () => {
  const { isLoggedInAdmin, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  const handleLogout = async () => {
    await logout();
    navigate("/admin/login");
  };

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleActiveLink = (path) => {
    if (path === "/dashboardadmin") {
      setActiveLink("");
    } else {
      setActiveLink(path);
    }
  };

  return (
    <nav className="bg-blue-600 p-4">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/admindashboard" className="flex-shrink-0">
          <img src={logo} className="w-20 sm:w-32 md:w-40 lg:w-28" alt="Logo" />
        </Link>
        {isLoggedInAdmin ? (
          <>
            <Link
              to="/admindashboard/carddetail"
              className={`text-sm md:hidden font-semibold py-2 px-3 rounded-md transition-colors duration-200 ease-in-out ${
                activeLink === "/admindashboard/carddetail"
                  ? "bg-orange-600 text-white"
                  : "border-2 border-orange-500 text-white hover:bg-orange-500 hover:text-white"
              }`}
              onClick={() => handleActiveLink("/admindashboard/carddetail")}
            >
              Add Plans
            </Link>

            <Link
              to="/admindashboard/userdetail"
              className={`text-sm md:hidden font-semibold py-2 px-3 rounded-md transition-colors duration-200 ease-in-out ${
                activeLink === "/admindashboard/userdetail"
                  ? "bg-orange-600 text-white"
                  : "border-2 border-orange-500 text-white hover:bg-orange-500 hover:text-white"
              }`}
              onClick={() => handleActiveLink("/admindashboard/userdetail")}
            >
              User Detail
            </Link>

            <Link
              to="/admindashboard/shipping"
              className={`text-sm md:hidden font-semibold py-2 px-3 rounded-md transition-colors duration-200 ease-in-out ${
                activeLink === "/admindashboard/shipping"
                  ? "bg-orange-600 text-white"
                  : "border-2 border-orange-500 text-white hover:bg-orange-500 hover:text-white"
              }`}
              onClick={() => handleActiveLink("/admindashboard/shipping")}
            >
              Track Orders
            </Link>
            <div className="flex space-x-4">
              <Link
                to="/admindashboard"
                className={`text-sm  font-semibold py-2 px-3 rounded-md transition-colors duration-200 ease-in-out ${
                  activeLink === "/admindashboard"
                    ? "border-2 border-orange-500 bg-orange-600 text-white"
                    : "border-2 border-orange-500 text-white hover:bg-orange-500 hover:text-white"
                }`}
                onClick={() => handleActiveLink("/admindashboard")}
              >
                Home
              </Link>
              <button
                onClick={handleLogout}
                className="text-sm bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
              >
                Logout
              </button>
            </div>
          </>
        ) : (
          <Link
            to="/admin/login"
            className="text-sm bg-yellow-500 text-white py-2 px-4 rounded hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-400"
          >
            Login
          </Link>
        )}
      </div>
    </nav>
  );
};

export default Header;
