import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../apiContstants";
import { useAuth } from "../../auth/AuthContext";
import { useNavigate } from "react-router-dom";

const ShipDetail = () => {
  const { isLoggedInAdmin } = useAuth()
  const [shipDetail, setShipDetail] = useState(null);
  const navigate = useNavigate()

  useEffect(() => {
    const isLoggedInAdmin = localStorage.getItem("isLoggedInAdmin")
    if (!isLoggedInAdmin) {
      navigate("/admin/login")
    }
  }, [isLoggedInAdmin, navigate])

  useEffect(() => {
    const fetchShip = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}api/shiprocket/allSuccessfullOrderDetails`
        );
        const responseData = response.data;
        // console.log(responseData);
        setShipDetail(responseData);
      } catch (error) {
        // alert("No Data Right Now");
        console.log("Error in responseData", error);
      }
    };
    fetchShip();
  }, []);

  return (
    <div className="w-full lg:w-9/12 md:w-9/12 sm:w-11/12 mt-11">
      <section className="flex flex-col">
        <div className="bg-white p-4 rounded-lg shadow-sm">
          <div className="flex justify-between items-center gap-3">
            <h3 className="text-lg font-semibold">Shipping Details</h3>
          </div>
          <table className="table-auto w-full mt-3">
            <thead>
              <tr className="text-left border-b">
                <th className="py-2">Customer Name</th>
                <th className="py-2">Order No</th>
                <th className="py-2">ShipRocket Order Id</th>
                <th className="py-2">Order Date</th>
                <th className="py-2">Invoice</th>
                <th className="py-2">Label</th>
              </tr>
            </thead>
            <tbody>
              {shipDetail && shipDetail?.map((order) => (
                <tr key={order.shiprocketOrderId} className="border-b">
                  <td className="py-2">{order.order_id}</td>
                  <td className="py-2">{`${order.shipping_customer_name} ${order.shipping_customer_name}`}</td>
                  <td className="py-2">{order.shiprocketOrderId}</td>
                  <td className="py-2">{order.orderDate}</td>
                  <td className="py-2">
                    <button
                      className="text-sm bg-blue-500 text-white px-3 py-1 rounded"
                      onClick={() => window.open(order.invoice, "_blank")}
                    >
                      Download Invoice
                    </button>
                  </td>
                  <td className="py-2">
                    <button
                      className="text-sm bg-blue-500 text-white px-3 py-1 rounded"
                      onClick={() => window.open(order.label, "_blank")}
                    >
                      Download Label
                    </button>
                  </td>
                </tr>
              ))}
              {shipDetail?.length === 0 && (
                <tr>
                  <td colSpan="5" className="text-center font-semibold py-4">
                    No orders found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default ShipDetail;
