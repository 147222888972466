import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import palm from "../assets/palm1.png";
import "./login.css"
import zodiac from "../../Admin/assets/astro.jpg";
import Header from "../component/Header.js/Header";

const LoginForm = () => {
  const [userLogin, setUserLogin] = useState({
    emailOrMobileNumber: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const { isLoggedInAdmin, login, userProfile } = useAuth();
  const [ passwordError, setPasswordError ] = useState("")
  const navigate = useNavigate();

  useEffect(() => {
    if (userProfile) {
        navigate("/admindashboard");
      }
    }, [userProfile, navigate]);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserLogin((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const passwordValidation = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!passwordValidation.test(userLogin.password)) {
      setPasswordError(
        "Password must contain at least 8 characters, including uppercase, lowercase, a number, and a special character."
      );
      return;
    }
    login(userLogin);
  };

  return (
    <div
      style={{
        backgroundImage: `url(${zodiac})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Header />
      <div className="flex justify-center items-center min-h-screen">
        <div className="w-full mx-4 flex flex-wrap">
          <div className="hidden md:flex md:w-1/2 justify-center items-center pl-28 md:pl-0">
            <img src={palm} alt="palm" className="max-w-full hand-animation" />
          </div>
          <div className="w-full md:w-1/2 flex justify-center items-center">
            <div className="w-full max-w-md">
              <div className="bg-white shadow-lg rounded-lg">
                <div className="bg-blue-500 px-6 py-4 text-center rounded-t-lg">
                  <h5 className="text-lg font-semibold">Login Form</h5>
                </div>
                <div className="px-6 py-4">
                  {!isLoggedInAdmin && (
                    <form onSubmit={handleSubmit}>
                      <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium mb-1">
                          Email address
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="emailOrMobileNumber"
                          placeholder="Enter email"
                          value={userLogin.emailOrMobileNumber}
                          onChange={handleChange}
                          required
                          className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </div>
                      <div className="mb-4 relative">
                        <label htmlFor="password" className="block text-sm font-medium mb-1">
                          Password
                        </label>
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          name="password"
                          placeholder="Enter password"
                          value={userLogin.password}
                          onChange={handleChange}
                          required
                          className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <button
                          type="button"
                          className="absolute top-2/3 right-3 transform -translate-y-1/2 p-1 text-gray-500"
                          onClick={() => togglePasswordVisibility("password")}
                        >
                          {showPassword.password ? "👁️" : "👁️"}
                        </button>
                        {passwordError && (
                          <p className="text-red-500 text-sm mt-2">{passwordError}</p>
                        )}
                      </div>
                      <div className="mt-6">
                        <button
                          type="submit"
                          className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition-colors"
                        >
                          Login
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default LoginForm;
