import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import LOGO from "../assets/images/logo.png";
import LOGO_ICON from "../assets/images/login icon.png";
import { useUser } from "../authentication/AuthProvider";
import Profile from "./homescreen/Profile";
import { BASE_URL } from "../apiContstants";
import loader from "../assets/images/loader1.gif";

function CustomerData() {
  const [userdetail, setUserDetail] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    timeOfBirth: "",
    pincode: "",
    location: "",
    language: "en",
    style: "",
    uploadImage: "",
    mobileNumber: "",
    gender: "",
  });

  const { isLoggedIn, loginUser, setLoginUser } = useUser();
  const [villages, setVillages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);

  const userProfileId =
    loginUser && loginUser.length > 0 ? loginUser[0].userProfileId : null;

  useEffect(() => {
    const fetchVillages = async () => {
      if (userdetail.pincode.length === 6) {
        try {
          const response = await fetch(
            `https://india-pincode-with-latitude-and-longitude.p.rapidapi.com/api/v1/pincode/${userdetail.pincode}`,
            {
              method: "GET",
              headers: {
                "X-RapidAPI-Host":
                  "india-pincode-with-latitude-and-longitude.p.rapidapi.com",
                "X-RapidAPI-Key":
                  "92dd61a3abmsh30aff286f1de18bp19ce46jsnd13651517f4e",
              },
            }
          );
          if (!response.ok) {
            throw new Error("Failed to fetch villages");
          }
          const data = await response.json();
          setVillages(data);
        } catch (err) {
          console.log("in pincode");
        }
      } else {
        setVillages([]);
      }
    };

    fetchVillages();
  }, [userdetail.pincode]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    const file = files ? files[0] : null;
    let newValue = value;

    if (name === "mobileNumber") {
      if (/^\d{0,10}$/.test(value)) {
        newValue = value;
      } else {
        return;
      }
    } else if (name === "pincode") {
      if (/^\d{0,6}$/.test(value)) {
        newValue = value;
      } else {
        return;
      }
    }

    if (file) {
      const validTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!validTypes.includes(file.type)) {
        alert("Please upload an image in JPG, JPEG, or PNG format.");
        e.target.value = "";
        return;
      }

      const maxSize = 2 * 1024 * 1024;
      if (file.size > maxSize) {
        alert("File size should be less than 2MB.");
        e.target.value = "";
        return;
      }
    }

    setUserDetail((prevState) => ({
      ...prevState,
      [name]: name === "uploadImage" ? files[0] : newValue,
    }));
  };

  const planType =
    Array.isArray(loginUser) && loginUser.length > 0
      ? loginUser
          .map((user) => user.planType)
          .filter((type) => type)
          .join(",")
      : loginUser && loginUser.planType
      ? loginUser.planType
      : "";

  const handleProceed = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const data = new FormData();
      for (const [key, value] of Object.entries(userdetail)) {
        data.append(key, value);
      }

      // for (let [key, value] of data.entries()) {
      //   console.log(`${key}: ${value}`);
      // }

      const response = await fetch(
        `${BASE_URL}api/userProfile/update1/${userProfileId}/${planType}`,
        {
          method: "PUT",
          body: data,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit data. Please try again.");
      }

      const result = await response.json();
      setLoginUser(result);

      const planType1 = localStorage.getItem("planType");
      // console.log(planType1)
      if (planType1 && planType1.includes("Web")) {
        navigate("/dashboard");
      } else {
        navigate("/");
      }
    } catch (err) {
      setError("Failed to submit data. Please try again.");
      console.log("submit");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-img min-h-screen bg-cover bg-center md:pb-6 pb-[9vh]">
      <div
        className="flex flex-row justify-between w-full md:h-24 h-16 items-center px-4 lg:px-24 md:px-9"
        style={{ background: "#1b1919" }}
      >
        <div className="flex justify-center md:justify-start">
          <Link to="/">
            <img src={LOGO} className="h-16 md:h-36" alt="Logo" />
          </Link>
        </div>
        <div className="flex flex-row gap-4 md:gap-8 lg:gap-24 text-sm md:text-base lg:text-xl text-white">
          {isLoggedIn ? (
            <Profile />
          ) : (
            <Link to="/login" className="Poppins cursor-pointer">
              Login
            </Link>
          )}
          {!isLoggedIn && (
            <Link to="/signup" className="Poppins cursor-pointer">
              Signup
            </Link>
          )}
        </div>
      </div>

      <div className="flex items-center justify-center xl:mt-12 mt-[8vh]">
        <div className="bg-white xl:w-[38%] lg:w-[40%] md:w-2/3 md:mx-0 mx-4 py-6 rounded-2xl relative">
          <img
            src={LOGO_ICON}
            alt="Your Image"
            className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:w-24 md:h-24 h-20 w-20 object-cover rounded-full"
          />

          <h1 className="md:text-2xl text-1xl text-center font-bold arial px-9 tracking-tighter md:mt-16 mt-11">
            Please Enter Your Details
          </h1>

          {loading ? (
            <div className="flex justify-center items-center w-full h-full">
              <img src={loader} alt="loader" />
            </div>
          ) : (
            <form className="mt-4 px-12" onSubmit={handleProceed}>
              <div className="flex flex-wrap -mx-2">
                <div className="w-1/2 px-2 mb-4">
                  <label>
                    First Name
                    <input
                      type="text"
                      name="firstName"
                      className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none"
                      placeholder="First Name"
                      value={userdetail.firstName}
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                </div>
                <div className="w-1/2 px-2 mb-4">
                  <label>
                    Last Name
                    <input
                      type="text"
                      name="lastName"
                      className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none"
                      placeholder="Last Name"
                      value={userdetail.lastName}
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                </div>
              </div>

              <div className="flex flex-wrap -mx-2">
                <div className="w-1/2 px-2 mb-4">
                  <label>
                    Mobile Number
                    <input
                      type="number"
                      name="mobileNumber"
                      className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none"
                      placeholder="Mobile Number"
                      pattern="[0-9]{10}"
                      maxLength="10"
                      value={userdetail.mobileNumber}
                      min="0"
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                </div>
                <div className="w-1/2 px-2 mb-4">
                  <label className="block mb-1">Gender</label>
                  <div className="flex items-center space-x-4">
                    <label className="flex items-center space-x-1">
                      <input
                        type="radio"
                        name="gender"
                        value="male"
                        className="text-[14px] placeholder-black border-gray-300 outline-none"
                        onChange={handleInputChange}
                        required
                      />
                      <span>Male</span>
                    </label>
                    <label className="flex items-center space-x-1">
                      <input
                        type="radio"
                        name="gender"
                        value="female"
                        className="text-[14px] placeholder-black border-gray-300 outline-none"
                        onChange={handleInputChange}
                        required
                      />
                      <span>Female</span>
                    </label>
                    <label className="flex items-center space-x-1">
                      <input
                        type="radio"
                        name="gender"
                        value="others"
                        className="text-[14px] placeholder-black border-gray-300 outline-none"
                        onChange={handleInputChange}
                        required
                      />
                      <span>Others</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap -mx-2">
                <div className="w-1/2 px-2 mb-4">
                  <label>
                    Date of Birth
                    <input
                      type="date"
                      name="dateOfBirth"
                      className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none"
                      value={userdetail.dateOfBirth}
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                </div>
                <div className="w-1/2 px-2 mb-4">
                  <label>
                    Time Of Birth
                    <input
                      type="time"
                      name="timeOfBirth"
                      className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none"
                      value={userdetail.timeOfBirth}
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                </div>
              </div>

              <div className="flex flex-wrap -mx-2">
                <div className="w-1/2 px-2 mb-4">
                  <label>
                    Pincode
                    <input
                      type="number"
                      name="pincode"
                      className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none"
                      placeholder="Pincode"
                      pattern="[0-9]{6}"
                      maxLength="6"
                      value={userdetail.pincode}
                      min="0"
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                </div>
                <div className="w-1/2 px-2 mb-4">
                  <label>
                    Village
                    <select
                      name="location"
                      className="arial text-[14px] border border-gray-300 w-full rounded-md p-2 outline-none"
                      value={userdetail.location}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="" disabled>
                        Select Village
                      </option>
                      {villages.length > 0 ? (
                        villages.map((village, index) => (
                          <option key={index} value={village.area}>
                            {village.area}
                          </option>
                        ))
                      ) : (
                        <option disabled value="">
                          Invalid pin code.
                        </option>
                      )}
                    </select>
                  </label>
                </div>
              </div>

              <div className="mb-4">
                <label>
                  Style
                  <select
                    name="style"
                    className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none bg-white"
                    value={userdetail.style}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Style</option>
                    <option value="north">North</option>
                    <option value="south">South</option>
                  </select>
                </label>
              </div>
              <div className="mb-4">
                <label>
                  Select Language
                  <br />
                  <select
                    name="language"
                    onChange={handleInputChange}
                    value={userdetail.language}
                    className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none"
                    required
                  >
                    {/* <option value="" disabled>
                    Select Language
                  </option> */}
                    <option value="en">English</option>
                    {/* <option value="be">Bengali (Bangla)</option>
                  <option value="hi">Hindi</option>
                  <option value="ka">Kannada</option>
                  <option value="ml">Malayalam</option>
                  <option value="ta">Tamil</option>
                  <option value="te">Telugu</option>
                  <option value="sp">Spain</option>
                  <option value="fr">France</option> */}
                  </select>
                </label>
              </div>
              <label>
                Upload Image
                <input
                  name="uploadImage"
                  type="file"
                  className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none bg-white"
                  onChange={handleInputChange}
                />
              </label>

              <div className="flex justify-center mt-3">
                <button
                  type="submit"
                  className="bg-black text-white py-2 px-10 rounded-md"
                  disabled={loading}
                >
                  {loading ? "Saving..." : "Proceed"}
                </button>
              </div>

              {error && (
                <div className="text-red-500 text-center mt-4">{error}</div>
              )}
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomerData;
