import React, { useEffect, useState } from "react";
import LOGO from "../assets/images/logo.png";
import LOGO_ICON from "../assets/images/login icon.png";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "./AuthProvider";
import axios from "axios";
import Profile from "../components/homescreen/Profile";
import { BASE_URL } from "../apiContstants";

function ChangePassword() {
  const [formData, setFormData] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
    error: "",
  });
  const { isLoggedIn, loginUser } = useUser();
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    password: false,
    confirmPassword: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);

  const userProfileId =
    Array.isArray(loginUser) && loginUser.length > 0
      ? loginUser[0].userProfileId
      : (loginUser && loginUser.userProfileId) || "";

  const togglePasswordVisibility = (field) => {
    setShowPassword((prevVisible) => ({
      ...prevVisible,
      [field]: !prevVisible[field],
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      error: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (
      !formData.oldPassword ||
      !formData.password ||
      !formData.confirmPassword
    ) {
      setFormData({
        ...formData,
        error: "All fields are mandatory",
      });
      return;
    }

    if (!passwordRegex.test(formData.password)) {
      setFormData({
        ...formData,
        error:
          "Password must be at least 8 characters long, include at least 1 uppercase, 1 lowercase, 1 number, and 1 special character",
      });
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setFormData({
        ...formData,
        error: "Password and Confirm Password does not matched",
      });
      return;
    }

    const signupData = new FormData();
    signupData.append("oldPassword", formData.oldPassword);
    signupData.append("password", formData.password);
    signupData.append("confirmPassword", formData.confirmPassword);

    try {
      const response = await axios.post(
        `${BASE_URL}api/userProfile/changePassword/${userProfileId}`,
        signupData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      alert(response.data);

      setFormData({
        oldPassword: "",
        password: "",
        confirmPassword: "",
        error: "",
      });
      navigate("/");
    } catch (error) {
      console.log("Something went wrong", error);
      const errorMessage =
        error.response?.data?.message ||
        "Failed to change password. Please try again.";
      setFormData({
        ...formData,
        error: errorMessage,
      });
    }
  };

  const handleCancel = () => {
    navigate("/");
    setFormData({
      oldPassword: "",
      password: "",
      confirmPassword: "",
      error: "",
    });
  };

  const planType = localStorage.getItem("planType");
  const firstName =
    loginUser && Array.isArray(loginUser) && loginUser.length > 0
      ? loginUser[0].firstName
      : loginUser?.firstName;

  return (
    <div className="bg-img min-h-screen bg-cover bg-center md:pb-6 pb-[9vh]">
      <div
        className="flex flex-row justify-between w-full md:h-24 h-16 items-center px-4 lg:px-24 md:px-9"
        style={{ background: "#1b1919" }}
      >
        <div className="flex justify-center md:justify-start">
          <Link to="/">
            <img src={LOGO} className="h-16 md:h-36" alt="logo" />
          </Link>
        </div>
        <div className="flex flex-row gap-4 md:gap-8 lg:gap-24 text-sm md:text-base lg:text-xl text-white">
          {isLoggedIn ? (
            <>
              <Link to="/" className="Poppins text-white cursor-pointer">
                Home
              </Link>
              {firstName !== null &&
                (planType.includes("Web") ||
                  planType.includes("Card and Web")) && (
                  <dl>
                    <Link
                      to="/dashboard"
                      className="Poppins text-white cursor-pointer"
                    >
                      Dashboard
                    </Link>
                  </dl>
                )}
              <Profile />
            </>
          ) : (
            <Link to="/login" className="Poppins cursor-pointer">
              Login
            </Link>
          )}
          {!isLoggedIn && (
            <Link to="/signup" className="Poppins cursor-pointer">
              Signup
            </Link>
          )}
        </div>
      </div>

      <div className="flex items-center justify-center xl:mt-20 mt-[8vh]">
        <div className="bg-white xl:w-1/4 lg:w-[40%] md:w-2/3 md:mx-0 mx-4 py-6 rounded-2xl relative">
          <img
            src={LOGO_ICON}
            alt="Your Image"
            className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:w-24 md:h-24 h-20 w-20 object-cover rounded-full"
          />

          <h1 className="md:text-2xl text-1xl text-center font-bold arial px-9 tracking-tighter md:mt-16 mt-11">
            Reset your password
          </h1>

          <form className="mt-4 px-12" onSubmit={handleSubmit}>
            <div className="relative">
              <input
                type={showPassword.oldPassword ? "text" : "password"}
                name="oldPassword"
                className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 mt-4 outline-none"
                placeholder="Old Password"
                value={formData.oldPassword}
                onChange={handleInputChange}
              />
              <button
                type="button"
                className="absolute right-3 top-2/3 transform -translate-y-1/2"
                onClick={() => togglePasswordVisibility("oldPassword")}
              >
                {showPassword.oldPassword ? "👁️" : "👁️"}
              </button>
            </div>
            <div className="relative">
              <input
                type={showPassword.password ? "text" : "password"}
                name="password"
                className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 mt-4 outline-none"
                placeholder="New Password"
                value={formData.password}
                onChange={handleInputChange}
              />
              <button
                type="button"
                className="absolute right-3 top-2/3 transform -translate-y-1/2"
                onClick={() => togglePasswordVisibility("password")}
              >
                {showPassword.password ? "👁️" : "👁️"}
              </button>
            </div>

            <div className="relative">
              <input
                type={showPassword.confirmPassword ? "text" : "password"}
                name="confirmPassword"
                className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 mt-4 outline-none"
                placeholder="Confirm Password"
                value={formData.confirmPassword}
                onChange={handleInputChange}
              />
              <button
                type="button"
                className="absolute right-3 top-2/3 transform -translate-y-1/2"
                onClick={() => togglePasswordVisibility("confirmPassword")}
              >
                {showPassword.confirmPassword ? "👁️" : "👁️"}
              </button>
            </div>
            {formData.error && (
              <p className="text-red-500 text-center mt-4">{formData.error}</p>
            )}

            <div className="flex justify-center gap-2 mt-6 my-4">
              <button
                type="submit"
                style={{ background: "#000134", fontSize: "15px" }}
                className="w-full rounded-md py-2 px-1 text-white Poppins"
              >
                Reset Password
              </button>
              <button
                type="button"
                onClick={handleCancel}
                className="w-full rounded-md py-2 text-white font-medium Poppins bg-red-500 hover:bg-red-600"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
