import React, { createContext, useContext, useEffect, useState } from "react";
import { BASE_URL } from "../apiContstants";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [signUp, setSignUp] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return localStorage.getItem('isLoggedIn') === 'true';
  });
  const [loginUser, setLoginUser] = useState(() => {
    const storedUser = localStorage.getItem('loginUser');
    return storedUser ? JSON.parse(storedUser) : [];
  });
  const [error, setError] = useState("");

  // console.log(loginUser)
  
  useEffect(() => {
    localStorage.setItem('isLoggedIn', isLoggedIn);
  }, [isLoggedIn]);

  useEffect(() => {
    localStorage.setItem('loginUser', JSON.stringify(loginUser));
  }, [loginUser]);

  const signup = async (formData) => {
    try {
      const data = new FormData();
      for (const [key, value] of Object.entries(formData)) {
        data.append(key, value);
      }
      const signupResponse = await fetch(
        `${BASE_URL}api/userProfile/save`,
        {
          method: "POST",
          body: data,
        }
      );
      const signupData = await signupResponse.json();
      setSignUp(signupData);
      setError("");
      return true;
    } catch (error) {
      console.log("Error signing up:");
      setError("Something went wrong. Please try again.");
    }
  };
  

  const loginMethod = async (loginUserData) => {
    try {
      const data = new FormData();
      for (const [key, value] of Object.entries(loginUserData)) {
        data.append(key, value);
      }
      const loginResponse = await fetch(`${BASE_URL}api/userProfile/login`, {
        method: "POST",
        body: data,
      });
      const loginData = await loginResponse.json();
      setLoginUser(loginData)
      if (loginResponse.status === 200) {
        setIsLoggedIn(true);
        setError("");
        if (Array.isArray(loginData) && loginData.length > 0 && loginData.map(user => user.planType)) {
          localStorage.setItem('planType', loginData.map(user => user.planType));
          localStorage.setItem('shipping_customer_name', loginData.map(user => user.shipping_customer_name));
        }
        return true;
      } else {
        setError(loginData.message);
        return false;
      }
    } catch (error) {
      setError(error.message);
      return false;
    }
  };

  const logout = async () => {
    setLoginUser(null)
    setIsLoggedIn(false);
    setError("");
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('loginUser');
    localStorage.removeItem('planType');
    localStorage.removeItem('planValidity');
    localStorage.removeItem('plans');
    localStorage.removeItem('shipping_customer_name');
    return true;
  };

  return (
    <AuthContext.Provider
      value={{ error, setSignUp, isLoggedIn, signup, loginMethod, logout, loginUser, setLoginUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useUser = () => useContext(AuthContext);
