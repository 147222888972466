import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { BASE_URL } from "../../../apiContstants";

export const PlansContext = createContext();

export const PlansProvider = ({ children }) => {
  const [allPlans, setAllPlans] = useState([]);

  const fetchAllPlans = async () => {
    try {
      const response = await axios.get(`${BASE_URL}api/plans/fetchAllPlans`);
      setAllPlans(response.data);
    } catch (error) {
      console.log("Error fetching Data: " + error);
    }
  };

  useEffect(() => {
    fetchAllPlans();
  }, []);

  return (
    <PlansContext.Provider value={{ allPlans, setAllPlans, fetchAllPlans }}>
      {children}
    </PlansContext.Provider>
  );
};

export const useAllPlans = () => useContext(PlansContext)