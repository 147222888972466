import React from 'react'
import Homescreen from '../components/homescreen/Homescreen'
import { Route, Routes } from 'react-router-dom'
import Login from '../authentication/Login'
import Signup from '../authentication/Signup'
import ForgetPassword from '../authentication/ForgetPassword'
import CustomerData from '../components/CustomerData'
import Dashboard from '../dashboard/Dashboard'
import UpdateProfile from '../components/homescreen/UpdateProfile'
import Plans from '../components/plans/Plans'
import OrderTrack from '../components/shipping/OrderTrack.jsx'
import ShippingAddress from '../components/shipping/ShippingAddress'

//Admin routing starts from here

import LoginAdmin from '../Admin/auth/LoginAdmin.js'
import DashboardAdmin from '../Admin/component/dashboard/DashboardAdmin'
import UserDetails from '../Admin/component/user/UserDetails'
import CardDetails from '../Admin/component/card/CardDetails'
import TrackCard from '../Admin/component/cardDispatchTrack/TrackCard'
import AdminDashboard from '../Admin/component/admindashboard/AdminDashboard.js'
import ChangePassword from '../authentication/ChangePassword.jsx'
import HowToUse from '../components/homescreen/HowToUse.jsx'
import Page404 from '../Page404.jsx'
import InvoiceBill from '../components/ChargesAndBills.jsx/InvoiceBill.jsx'
import ShipDetail from '../Admin/component/shippingDetails/ShipDetail.js'

function Mainroute() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Homescreen />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/customerData" element={<CustomerData />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/forgotPassword" element={<ForgetPassword />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/updateProfile' element={<UpdateProfile />} />
        <Route path='/orderstatus' element={<OrderTrack />} />
        <Route path='/shipadd' element={<ShippingAddress />} />
        <Route path='/changepassword' element={<ChangePassword />} />
        <Route path='/howtouse' element={<HowToUse />} />
        <Route path='/downladinvoice' element={<InvoiceBill />} />
        <Route path='*' element={<Page404 />} />

        {/* Admin Routing starts here */}

        <Route path="/admin/login" element={<LoginAdmin />} />
        <Route path="/admindashboard" element={<DashboardAdmin />} >
          <Route path='/admindashboard' element={<AdminDashboard />} />
          <Route path="userdetail" element={<UserDetails />} />
          <Route path="carddetail" element={<CardDetails />} />
          <Route path="shipping" element={<TrackCard />} />
          <Route path="shiporder" element={<ShipDetail />} />
        </Route>
      </Routes>
    </div>
  )
}

export default Mainroute