import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { BASE_URL } from "../../apiContstants";

const AuthContext = createContext();

export const AuthProviderAdmin = ({ children }) => {
  const [isLoggedInAdmin, setIsLoggedIn] = useState(false);
  const [ userProfile, setUserProfile ] = useState(null)

  useEffect(() => {
    const storedLoginStatus = localStorage.getItem("isLoggedInAdmin");
    const storedUserProfile = localStorage.getItem("login");

    if (storedLoginStatus === "true" && storedUserProfile) {
      setIsLoggedIn(true);
      setUserProfile(JSON.parse(storedUserProfile));
    } else {
      setIsLoggedIn(false);
      setUserProfile(null);
    }
  }, []);

  const login = async(userLogin) => {
    try {
      const formData = new FormData();
      formData.append("emailOrMobileNumber", userLogin.emailOrMobileNumber )
      formData.append("password", userLogin.password )

      const response = await axios.post(`${BASE_URL}api/admin/login`, formData,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },
    })
      const loginResponse = response.data
      setIsLoggedIn(true)
      setUserProfile(loginResponse)
      localStorage.setItem("isLoggedInAdmin", "true")
      localStorage.setItem("login", JSON.stringify(loginResponse))
    } catch (error) {
      console.log("Login Error", error)
    }
  };

  const logout = () => {
    setIsLoggedIn(false);
    setUserProfile(null)
    localStorage.removeItem("isLoggedInAdmin");
    localStorage.removeItem("login");
  };

  return (
    <AuthContext.Provider value={{ isLoggedInAdmin, login, logout, userProfile }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
