import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../apiContstants';

const AdminDashboard = () => {
  const { isLoggedInAdmin } = useAuth();
  const [cardData, setCardData] = useState({
    activeUsers: [],
    totalSale: {
      Web: 0,
      Card: 0,
      Premium: 0
    },
    totalRevenue: 0,
  });
  const [loading, setLoading] = useState(true);
  const [expand, setExpand] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedInAdmin) {
      navigate("/admin/login");
    }
  }, [isLoggedInAdmin, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responses = [
          await axios.get(`${BASE_URL}api/payments/totalActiveUsersCount`),
          await axios.get(`${BASE_URL}api/payments/total-Active-users/count-by-plan`),
          await axios.get(`${BASE_URL}api/payments/total-revenue`)
        ];
    
        const [activeUsersResponse, totalSaleResponse, totalRevenueResponse] = responses;
    
        setCardData({
          activeUsers: activeUsersResponse.data,
          totalSale: {
            Web: totalSaleResponse.data.totalWebUsersCount,
            Card: totalSaleResponse.data.totalCardUsersCount,
            Premium: totalSaleResponse.data.totalCardAndWebUsersCount
          },
          totalRevenue: totalRevenueResponse.data
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleExpandToggle = (section) => {
    setExpand(prevExpand => (prevExpand === section ? null : section));
  };

  return (
    <div className="w-full lg:w-9/12 mt-4">
      <div className="mt-7">
        <div className="flex flex-wrap mt-4">

          <div className="w-full sm:w-1/2 lg:w-1/3 p-2">
            <div className="bg-sky-200 border rounded-lg mb-3">
              <div className="p-4">
                <div className="flex items-center mb-2">
                  <div>
                    <h5 className="text-lg font-semibold">Total Active Users</h5>
                    <p className="text-2xl font-bold">
                      {loading ? 'Loading...' : cardData.activeUsers}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 lg:w-1/3 p-2">
            <div
              className="bg-sky-200 border rounded-lg mb-3 cursor-pointer"
              onClick={() => handleExpandToggle('totalSale')}
            >
              <div className="p-4">
                <div className="flex items-center mb-2">
                  <div>
                    <h5 className="text-lg font-semibold">Total Sale</h5>
                    <p className="text-2xl font-bold">
                      {loading
                        ? 'Loading...'
                        : cardData.totalSale.Web + cardData.totalSale.Card + cardData.totalSale.Premium}
                    </p>
                  </div>
                </div>
                {expand === 'totalSale' && !loading && (
                  <div className="text-left mt-3">
                    <p>
                      <strong>Web Sales:</strong> {cardData.totalSale.Web}
                    </p>
                    <p>
                      <strong>Card Sales:</strong> {cardData.totalSale.Card}
                    </p>
                    <p>
                      <strong>Web and Card Sales:</strong> {cardData.totalSale.Premium}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 lg:w-1/3 p-2">
            <div
              className="bg-sky-200 border rounded-lg mb-3 cursor-pointer"
              onClick={() => handleExpandToggle('totalRevenue')}
            >
              <div className="p-4">
                <div className="flex items-center mb-2">
                  <div>
                    <h5 className="text-lg font-semibold">Total Revenue</h5>
                    <p className="text-2xl font-bold">
                      {loading ? 'Loading...' : `${cardData.totalRevenue.totalPaymentsAmount || 0}/-`}
                    </p>
                  </div>
                </div>
                {expand === 'totalRevenue' && !loading && (
                  <div className="text-left mt-3">
                    <p>
                      <strong>Web:</strong> {cardData.totalRevenue.totalWebRevenue || 0}/-
                    </p>
                    <p>
                      <strong>Card:</strong> {cardData.totalRevenue.totalCardRevenue || 0}/-
                    </p>
                    <p>
                      <strong>Card and Web:</strong> {cardData.totalRevenue.totalCardAndWebRevenue || 0}/-
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
