import React from "react";
import Sidebar from "../sidebar/Sidebar";
import Header from "../Header.js/Header";
import { Outlet } from "react-router-dom";
import zodiac from "../../assets/astro.jpg";

function Dashboard() {

  return (
    <div>
    <div
    style={{
      backgroundImage: `url(${zodiac})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    }}
  >
      <Header />
      <div className="flex justify-center gap-4 px-2 min-h-screen mx-auto w-full">
        <Sidebar className="w-1/4" />
        <Outlet />
      </div>
    </div>
    </div>

  );
}

export default Dashboard;
