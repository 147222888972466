import React, { useEffect, useState } from "react";
import { useAuth } from "../../auth/AuthContext";
import axios from "axios";
import { BASE_URL } from "../../../apiContstants";
import { useNavigate } from "react-router-dom";

function TrackCard() {
  const { isLoggedInAdmin } = useAuth()
  const [ userTrack, setUserTrack ] = useState(null)
  const [statusFilter, setStatusFilter] = useState('All');
  const navigate = useNavigate()

  useEffect(() => {
    const isLoggedInAdmin = localStorage.getItem("isLoggedInAdmin")
    if (!isLoggedInAdmin) {
      navigate("/admin/login")
    }
  }, [isLoggedInAdmin, navigate])
  
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}api/shiprocket/getShipmentStatusForAllUsers`
        );
        // console.log(response.data)
        setUserTrack(response.data);
      } catch (error) {
        console.log("Error in response", error);
      }
    };
    fetchPlans();
  }, []);

  const processing = userTrack ? userTrack.filter((user) => user.statusCode === 1) : [];
  const ship = userTrack ? userTrack.filter((user) => user.statusCode === 6) : [];
  const delivered = userTrack ? userTrack.filter((user) => user.statusCode === 7) : [];

  return (
    <div className="w-full lg:w-9/12 md:w-9/12 sm:w-11/12 mt-11">
      <section className="mb-4">
        <h2 className="text-lg text-white font-bold mb-2">Overview</h2>
        <div className="flex flex-wrap -mx-2">
          <div className="w-full sm:w-1/2 lg:w-1/3 px-2 mb-3">
            <div className="p-4 rounded-lg shadow-sm text-center bg-sky-300">
              <h3 className="text-lg font-semibold">{processing.length}</h3>
              <p className="text-sm font-medium">Processing</p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/3 px-2 mb-3">
            <div className="p-4 rounded-lg shadow-sm text-center bg-sky-300">
              <h3 className="text-lg font-semibold">{ship.length}</h3>
              <p className="text-sm font-medium">Shipped</p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/3 px-2 mb-3">
            <div className="p-4 rounded-lg shadow-sm text-center bg-sky-300">
              <h3 className="text-lg font-semibold">{delivered.length}</h3>
              <p className="text-sm font-medium">Delivered</p>
            </div>
          </div>
        </div>
      </section>

      <section className="flex flex-col">
        <div className="bg-white p-4 rounded-lg shadow-sm">
          <div className="flex justify-between items-center gap-3 mt-3">
            <section>
              <h3 className="text-lg font-semibold">Last Orders</h3>
            </section>
            <section className="pr-8">
            <label htmlFor="statusFilter" className="font-medium pr-2">Filter by Status:</label>
            <select
              id="statusFilter"
              className="form-select border border-gray-500 p-1 rounded-md"
              value={statusFilter}
              onChange={e => setStatusFilter(e.target.value)}
            >
              <option value="All">All</option>
              <option value="Processing">Processing</option>
              <option value="Shipped">Shipped</option>
              <option value="Delivered">Delivered</option>
            </select>
            </section>
          </div>

          <table className="table-auto w-full mt-3">
            <thead>
              <tr className="text-left border-b">
                <th className="py-2">Order ID</th>
                <th className="py-2">Product</th>
                <th className="py-2">Customer</th>
                <th className="py-2">Status</th>
              </tr>
            </thead>
            <tbody>
              {userTrack?.map(order => (
                <tr key={order.order_id} className="border-b">
                  <td className="py-2">{order.order_id}</td>
                  <td className="py-2">AstroCard</td>
                  <td className="py-2">{`${order.shipping_customer_name} ${order.shipping_last_name}`}</td>
                  <td className="py-2">
                    <span className={`inline-block px-2 py-1 text-white rounded-full text-xs ${
                      order.statusCode === 1 ? 'Proccessing' :
                      order.statusCode === 6 ? 'Shipped' :
                      order.statusCode === 7 ? 'Delevered' :
                      'bg-green-500'
                    }`}>
                      {order.status}
                    </span>
                  </td>
                </tr>
              ))}
              {userTrack?.length === 0 && (
                <tr>
                  <td colSpan="5" className="text-center py-4">No orders found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
}

export default TrackCard;
