import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import wedding from "../../assets/images/wedding.png";
import axios from "axios";
import { useUser } from "../../authentication/AuthProvider";
import { BASE_URL } from "../../apiContstants";

const cardData = [
  {
    id: 1,
    title: "North Match",
    icon: wedding,
  },
  {
    id: 2,
    title: "North Match with Astro Details",
    icon: wedding
  },
  {
    id: 1,
    title: "South Match",
    icon: wedding,
  },
  {
    id: 2,
    title: "South Match with Astro Details",
    icon: wedding
  },
  {
    id: 3,
    title: "Dashakoot",
    icon: wedding,
  },
  {
    id: 4,
    title: "Dashakoot with Astro Details",
    icon: wedding,
  },
  {
    id: 5,
    title: "Aggregate Match",
    icon: wedding,
  },
  {
    id: 6,
    title: "Rajju Vedha Details",
    icon: wedding,
  },
  {
    id: 7,
    title: "Papasamaya Match",
    icon: wedding,
  },
  {
    id: 8,
    title: "Nakshatra Match",
    icon: wedding,
  },
  {
    id: 9,
    title: "Western Match",
    icon: wedding,
  },
  // {
  //   id: 10 ,
  //   title: "Bulk North Match",
  //   icon: planet,
  // },
  // {
  //   id: 11 ,
  //   title: "Bulk South Match",
  //   icon: planet,
  // },
  // {
  //   id: 12 ,
  //   title: "Bulk West Match",
  //   icon: planet,
  // },
];

function Matching() {
  const { loginUser } = useUser()
  const [user, setUser] = useState(null)
  const [partner, setPartner] = useState({
    partnerName: "",
    partnerGender: "",
    pincode: "",
    partnerDateOfBirth: "",
    partnerTimeOfBirth: "",
    partnerLanguage: "en",
    partnerSelectArea: "",
    partnerLatitude: "",
    partnerLongitude: "",
    partnerTimezone: "",
  })
  const [selectedCard, setSelectedCard] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [handleShowModal, setHandleShowModal] = useState(false);
  const [matchingData, setMatchingData] = useState(null)
  const [villages, setVillages] = useState([])

  const userProfileId = Array.isArray(loginUser) && loginUser.length > 0
    ? loginUser[0].userProfileId
    : loginUser.userProfileId || '';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}api/userProfile/webhook/${userProfileId}`
        );
        const data = response.data;
        setUser({
          Dob: data.dateOfBirth,
          Tob: data.timeOfBirth,
          Tz: data.timeZone,
          Lat: data.latitude,
          Lon: data.longitude,
          Gen: data.gender,
          Lang: data.language,
          Gen: data.gender,
        });
      } catch (error) {
        console.log("fetching data: Wbhk");
      }
    };

    if (userProfileId) {
      fetchData();
    }
  }, [userProfileId]);

  useEffect(() => {
    const fetchVillages = async () => {
      if (partner && partner.pincode && partner.pincode.length === 6) {
        try {
          const response = await fetch(
            `https://india-pincode-with-latitude-and-longitude.p.rapidapi.com/api/v1/pincode/${partner.pincode}`,
            {
              method: "GET",
              headers: {
                "X-RapidAPI-Host":
                  "india-pincode-with-latitude-and-longitude.p.rapidapi.com",
                "X-RapidAPI-Key": "92dd61a3abmsh30aff286f1de18bp19ce46jsnd13651517f4e",
              },
            }
          );
          if (!response.ok) {
            throw new Error("Failed to fetch villages");
          }
          const data = await response.json();
          setVillages(data);
        } catch (err) {
          console.log("pincode call");
        }
      } else {
        setVillages([]);
      }
    };

    fetchVillages();
  }, [partner.pincode]);

  const handleCardClick = (index) => {
    setSelectedCard(index);
    setShowModal(true)
  };

  const data = [];
  for (let i = 0; i < cardData.length; i += cardData.length) {
    data.push(cardData.slice(i, i + cardData.length));
  }

  // const handleDownload = () => {
  //   const doc = new jsPDF();

  //   const table = document.querySelector('.matching-table');
  //   if (table) {
  //     doc.html(table, {
  //       callback: function (doc) {
  //         doc.save('horoscope-data.pdf');
  //       },
  //       margin: [10, 10, 10, 10],
  //       x: 10,
  //       y: 10,
  //     });
  //   }
  // };

  const handleView = () => {
    setHandleShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const closeModal1 = () => {
    setHandleShowModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPartner((prevPartner) => ({
      ...prevPartner,
      [name]: value
    }));

    const selectedVillage = villages.find((village) => village.area === value);

    if (selectedVillage) {
      const { lat, lng } = selectedVillage;

      axios.get(`https://weatherapi-com.p.rapidapi.com/timezone.json`, {
        params: {
          q: `${lat},${lng}`,
        },
        headers: {
          "X-RapidAPI-Host": "weatherapi-com.p.rapidapi.com",
          "X-RapidAPI-Key": "92dd61a3abmsh30aff286f1de18bp19ce46jsnd13651517f4e",
        },
      })
        .then((response) => {
          const data = response.data.location
          setPartner((prevState) => ({
            ...prevState,
            partnerLatitude: data.lat,
            partnerLongitude: data.lon,
            partnerTimezone: data.tz_id,
          }));
        })
        .catch((error) => {
          console.log("fetching data: Tmzn");
        });
    }
  }

  const handleSubmit = async (e, index) => {
    e.preventDefault();
    const selectedCardId = cardData[index]?.id;
    const selectedCardTitle = cardData[index]?.title;
    const isLongTerm = cardData[index]?.isLongTerm;

    const userGender = user.Gen === "male"
    const requestData = {
      ...user,
      ...partner,
      cardId: selectedCardId,
      title: selectedCardTitle,
      userGender: userGender
    }

    try {
      const response = await axios.post(
        `${BASE_URL}api/matching/save/${userProfileId}`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = response.data;
      // console.log("API Response:", result);
      setMatchingData(result)
      setShowModal(false)
    } catch (error) {
      console.log("Error: match main");
    }
  }

  return (
    <div className="w-full sm:w-full lg:w-3/5">
      <div className="mt-5 flex-1">
        <h3 className="text-white font-mono">Matching</h3>
        {data.map((item, dataIndex) => (
          <div
            className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-2 mt-5 font-mono"
            key={dataIndex}
          >
            {item.map((card, cardIndex) => (
              <div className="col-span-1" key={cardIndex}>
                <button
                  className="w-full border rounded-lg mb-3 p-2 text-white cursor-pointer bg-black bg-opacity-40 lg:bg-opacity-35 sm:bg-opacity-40 shadow-lg shadow-cyan-500/50"
                  onClick={() =>
                    handleCardClick(dataIndex * cardData.length + cardIndex)
                  }
                >
                  <div className="card-body">
                    <div className="items-center">
                      <div className="circle-round">
                        <div className="icon-circle">
                          <img src={card.icon} alt="icon" className="h-6 w-6" />
                        </div>
                      </div>
                      <h5 className="sm:text-sm lg:text-xs xl:text-base p-1">
                        {card.title}
                      </h5>
                    </div>
                  </div>
                </button>
                {selectedCard === dataIndex * cardData.length + cardIndex && matchingData && matchingData.length > 0 && (
                  <div className="flex gap-4 mt-2">
                    <button
                      onClick={handleView}
                      className="text-xs p-2 bg-orange-500 text-white rounded-lg shadow-sm hover:bg-orange-700"
                    >
                      View Details
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
      
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Enter Partner Details</h2>
            <form className="mt-4 px-12" onSubmit={(e) => handleSubmit(e, selectedCard)}>
              <div className="text-[20px] fpx-2 mb-4">
                <label>
                  Name
                  <input
                    type="text"
                    name="partnerName"
                    className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none"
                    placeholder="Full Name"
                    value={partner.partnerName}
                    onChange={handleInputChange}
                    required
                  />
                </label>
              </div>
              <div className="text-[20px] flex flex-wrap -mx-2">
                <div className="w-1/2 px-2 mb-4">
                  <label className="block mb-1">Gender</label>
                  <div className="flex items-center space-x-4">
                    <label className="flex items-center space-x-1">
                      <input
                        type="radio"
                        name="partnerGender"
                        value="male"
                        checked={partner.partnerGender === "male"}
                        className="text-[14px] placeholder-black border-gray-300 outline-none"
                        onChange={handleInputChange}
                        required
                      />
                      <span>Male</span>
                    </label>
                    <label className="flex items-center space-x-1">
                      <input
                        type="radio"
                        name="partnerGender"
                        value="female"
                        checked={partner.partnerGender === "female"}
                        className="text-[14px] placeholder-black border-gray-300 outline-none"
                        onChange={handleInputChange}
                        required
                      />
                      <span>Female</span>
                    </label>
                    <label className="flex items-center space-x-1">
                      <input
                        type="radio"
                        name="partnerGender"
                        value="other"
                        checked={partner.partnerGender === "other"}
                        className="text-[14px] placeholder-black border-gray-300 outline-none"
                        onChange={handleInputChange}
                        required
                      />
                      <span>Others</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="text-[20px] flex flex-wrap -mx-2">
                <div className="w-1/2 px-2 mb-4">
                  <label>
                    Pincode
                    <input
                      type="number"
                      name="pincode"
                      className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none"
                      placeholder="Pincode"
                      value={partner.pincode}
                      min="0"
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                </div>
                <div className="w-1/2 px-2 mb-4">
                  <label>
                    Select Area
                    <select
                      name="location"
                      className="arial text-[14px] border border-gray-300 w-full rounded-md p-2 outline-none"
                      value={partner.location}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="" disabled>
                        Select Village
                      </option>
                      {villages.length > 0 ? (
                        villages.map((village, index) => (
                          <option key={index} value={village.area}>
                            {village.area}
                          </option>
                        ))
                      ) : (
                        <option disabled value="">
                          Invalid pin code.
                        </option>
                      )}
                    </select>
                  </label>
                </div>
              </div>
              <div className="text-[20px] flex flex-wrap -mx-2">
                <div className="w-1/2 px-2 mb-4">
                  <label>
                    Date of Birth
                    <input
                      type="date"
                      name="partnerDateOfBirth"
                      className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none"
                      value={partner.partnerDateOfBirth}
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                </div>
                <div className="w-1/2 px-2 mb-4">
                  <label>
                    Time Of Birth
                    <input
                      type="time"
                      name="partnerTimeOfBirth"
                      className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none"
                      value={partner.partnerTimeOfBirth}
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                </div>
              </div>

              <div className="text-[20px] -mx-2">
                <div className="px-2 mb-4">
                  <label>
                    Select Language
                    <br />
                    <select
                      name="partnerLanguage"
                      onChange={handleInputChange}
                      value={partner.partnerLanguage}
                      className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none"
                      required
                    >
                      {/* <option value="">
                        Select Language
                      </option> */}
                      <option value="en">English</option>
                      {/* <option value="be">Bengali (Bangla)</option>
                  <option value="hi">Hindi</option>
                  <option value="ka">Kannada</option>
                  <option value="ml">Malayalam</option>
                  <option value="ta">Tamil</option>
                  <option value="te">Telugu</option>
                  <option value="sp">Spain</option>
                  <option value="fr">France</option> */}
                    </select>
                  </label>
                </div>
              </div>
              <div className="text-[18px] flex justify-end mt-4">
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded-lg shadow-sm hover:bg-blue-700 mr-2"
                >
                  Match
                </button>
                <button
                  onClick={closeModal}
                  className="px-4 py-2 bg-red-500 text-white rounded-lg shadow-sm hover:bg-red-700"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {handleShowModal && matchingData && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h2 className="font-semibold mb-4">{Array.isArray(loginUser) && loginUser.length > 0
              ? loginUser[0].userName
              : loginUser.userName || ''} Matching</h2>

            <div className="w-full max-w-7xl max-h-96  overflow-y-auto">
              <table className="w-full border-collapse matching-table">
                <tbody>
                  {Object.entries(matchingData).map(([key, value]) => (
                    <React.Fragment key={key}>
                      <tr className="border-b">
                        <td className="p-2 font-semibold">{key.replace(/_/g, ' ')}</td>
                      <td className="p-2">
                        {typeof value === 'string' ? (
                          value
                        ) : (
                          <table className="w-full">
                            <tbody>
                              {Object.entries(value).map(([subKey, subValue]) => (
                                <tr key={subKey}>
                                  <td className="p-2 font-semibold">{subKey}</td>
                                  <td className="p-2">{subValue}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            
            {/* <button
              onClick={handleDownload}
              className="mt-4 p-3 bg-red-500 text-white text-sm rounded-lg shadow-sm hover:bg-red-700"
            >
              Download
            </button> */}

            <button
              onClick={closeModal1}
              className="mt-4 p-3 bg-red-500 text-white text-sm rounded-lg shadow-sm hover:bg-red-700 float-right"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Matching;
