import React from 'react';
import { useNavigate } from 'react-router-dom';

function Page404() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="text-center p-6 bg-white shadow-lg rounded-lg max-w-sm w-full">
        <h1 className="text-6xl font-extrabold text-red-500">404</h1>
        <p className="mt-4 text-xl text-gray-700">
          Oops! The page you&apos;re looking for doesn&apos;t exist.
        </p>
        <p className="mt-2 text-lg text-gray-500">Please check the URL or go back to the homepage.</p>
        <button
          onClick={()=>navigate("/")}
          className="mt-6 px-6 py-2 bg-blue-600 text-white text-lg font-semibold rounded-lg hover:bg-blue-700 transition"
        >
          Go to Home
        </button>
      </div>
    </div>
  );
}

export default Page404;
