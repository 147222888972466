import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./sidebar.css";

const Sidebar = ({ isOpen }) => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleActiveLink = (path) => {
    if (path === "/dashboardadmin") {
      setActiveLink("");
    } else {
      setActiveLink(path);
    }
  };

  return (
    <div className={`list-group w-full md:w-1/4 sidebar ${isOpen ? "open" : ""} bg-white shadow-lg rounded-lg p-4`}>
      <span className="text-lg font-bold pb-2 mb-4">Dashboard</span>

      <Link
        to="/admindashboard/carddetail"
        className={`font-semibold block py-2 px-3 rounded-md ${activeLink === "/admindashboard/carddetail" ? "bg-blue-500 text-white" : "text-gray-700 hover:bg-gray-200"}`}
        onClick={() => handleActiveLink("/admindashboard/carddetail")}
      >
        <i className={`fas fa-plus-circle mr-2 ${activeLink !== "/admindashboard/carddetail" ? "text-orange-500" : ""}`}></i> {/* Add Plans Icon */}
        Add Plans
      </Link>

      <Link
        to="/admindashboard/userdetail"
        className={`font-semibold block py-2 px-3 rounded-md ${activeLink === "/admindashboard/userdetail" ? "bg-blue-500 text-white" : "text-gray-700 hover:bg-gray-200"}`}
        onClick={() => handleActiveLink("/admindashboard/userdetail")}
      >
        <i className={`fas fa-users mr-2 ${activeLink !== "/admindashboard/userdetail" ? "text-orange-500" : ""}`}></i> {/* User Detail Icon */}
        User Detail
      </Link>

      <Link
        to="/admindashboard/shipping"
        className={`font-semibold block py-2 px-3 rounded-md ${activeLink === "/admindashboard/shipping" ? "bg-blue-500 text-white" : "text-gray-700 hover:bg-gray-200"}`}
        onClick={() => handleActiveLink("/admindashboard/shipping")}
      >
        <i className={`fas fa-truck mr-2 ${activeLink !== "/admindashboard/shipping" ? "text-orange-500" : ""}`}></i> {/* Track Orders Icon */}
        Track Orders
      </Link>

      <Link
        to="/admindashboard/shiporder"
        className={`font-semibold block py-2 px-3 rounded-md ${activeLink === "/admindashboard/shiporder" ? "bg-blue-500 text-white" : "text-gray-700 hover:bg-gray-200"}`}
        onClick={() => handleActiveLink("/admindashboard/shiporder")}
      >
        <i className={`fas fa-truck mr-2 ${activeLink !== "/admindashboard/shiporder" ? "text-orange-500" : ""}`}></i> {/* Track Orders Icon */}
        Shipping Orders
      </Link>
    </div>
  );
};

export default Sidebar;
