import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../../authentication/AuthProvider";
import InvoiceBill from "../ChargesAndBills.jsx/InvoiceBill";
import { PDFDownloadLink } from "@react-pdf/renderer";
// import axios from "axios";
// import { BASE_URL } from "../../apiContstants";

function Profile() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [invoiceData, setInvoiceData] = useState(null);
  const { logout, loginUser } = useUser();
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    localStorage.removeItem("userProfileId");
    localStorage.removeItem("shipping_customer_name");
    navigate("/");
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const imageSrc =
    Array.isArray(loginUser) && loginUser.length > 0 && loginUser[0].uplaodImage
      ? `data:image/png;base64,${loginUser[0].uplaodImage}`
      : loginUser.uplaodImage
      ? `data:image/png;base64,${loginUser.uplaodImage}`
      : "";

  let existingPlanType = localStorage.getItem("planType");

  try {
    existingPlanType = existingPlanType ? JSON.parse(existingPlanType) : [];
  } catch (error) {
    // console.warn("Invalid JSON in localStorage, resetting planType.");
    existingPlanType = [];
  }

  const planMap =
    Array.isArray(loginUser) && loginUser.length > 0
      ? loginUser.flatMap((user) => user.planType).filter((type) => type)
      : loginUser && loginUser.planType
      ? [loginUser.planType]
      : [];

  planMap.forEach((plan) => {
    if (!existingPlanType.includes(plan)) {
      existingPlanType.push(plan);
    }
  });

  localStorage.setItem("planType", JSON.stringify(existingPlanType));

  const planType = localStorage.getItem("planType");

  const profileValidLocal = localStorage.getItem("shipping_customer_name");

  const firstName =
    Array.isArray(loginUser) && loginUser.length > 0
      ? loginUser[0].firstName
      : loginUser.firstName;

  return (
    <div className="relative" ref={dropdownRef}>
      <button onClick={toggleDropdown}>
        <div className="relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
          {imageSrc ? (
            <img
              src={imageSrc}
              alt="Profile"
              className="w-full h-full object-cover"
            />
          ) : (
            <svg
              className="absolute w-12 h-12 text-gray-400 -left-1"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                clipRule="evenodd"
              ></path>
            </svg>
          )}
        </div>
      </button>

      {dropdownOpen && (
        <div
          id="userDropdown"
          className="absolute right-0 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
        >
          <div className="px-4 py-3 text-sm text-blue-700 dark:text-white font-bold">
            <div>
              {Array.isArray(loginUser) && loginUser.length > 0
                ? loginUser[0].userName
                : loginUser.userName || ""}
            </div>
          </div>

          {/* Home Link */}
          <dl>
            <Link
              to="/"
              className="block px-4 py-2 text-sm text-gray-900 hover:bg-purple-500 hover:text-white dark:text-white dark:hover:bg-purple-600 dark:hover:text-white rounded-md transition-all"
            >
              Home
            </Link>
          </dl>

          {/* Dashboard Link (for Web or Card and Web plan types) */}
          {firstName !== null &&
            (planType.includes("Web") || planType.includes("Card and Web")) && (
              <dl>
                <Link
                  to="/dashboard"
                  className="block px-4 py-2 text-sm text-gray-900 hover:bg-purple-500 hover:text-white dark:text-white dark:hover:bg-purple-600 dark:hover:text-white rounded-md transition-all"
                >
                  Dashboard
                </Link>
              </dl>
            )}

          {/* Update Profile Link */}
          {firstName !== null && planType && planType.length > 0 && (
            <dl>
              <Link
                to="/updateProfile"
                className="block px-4 py-2 text-sm text-gray-900 hover:bg-purple-500 hover:text-white dark:text-white dark:hover:bg-purple-600 dark:hover:text-white rounded-md transition-all"
              >
                Update Profile
              </Link>
            </dl>
          )}

          {/* Order Status Link (for Card or Card and Web plan types) */}
          {profileValidLocal &&
            (planType.includes("Card") ||
              planType.includes("Card and Web")) && (
              <dl>
                <Link
                  to="/orderstatus"
                  className="block px-4 py-2 text-sm text-gray-900 hover:bg-purple-500 hover:text-white dark:text-white dark:hover:bg-purple-600 dark:hover:text-white rounded-md transition-all"
                >
                  Order Status
                </Link>
              </dl>
            )}

          {profileValidLocal && (
            <dl>
              <PDFDownloadLink
                document={<InvoiceBill />}
                fileName="Invoice.pdf"
                className="block px-4 py-2 text-sm text-gray-900 hover:bg-purple-500 hover:text-white dark:text-white dark:hover:bg-purple-600 dark:hover:text-white rounded-md transition-all"
              >
                {({ loading }) =>
                  loading ? "Preparing document..." : "Download Invoice"
                }
              </PDFDownloadLink>
            </dl>
          )}

          {/* Change Password Link */}
          <dl>
            <Link
              to="/changepassword"
              className="block px-4 py-2 text-sm text-gray-900 hover:bg-purple-500 hover:text-white dark:text-white dark:hover:bg-purple-600 dark:hover:text-white rounded-md transition-all"
            >
              Change Password
            </Link>
          </dl>

          {/* Logout Button */}
          <div className="py-1 w-full">
            <button
              onClick={handleLogout}
              className="btn w-full text-start px-4 py-2 hover:bg-red-500 text-black hover:text-white dark:bg-red-500 rounded-md transition-all"
            >
              Logout
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;
