import React, { useState } from "react";
import LOGO from "../assets/images/logo.png";
import LOGO_ICON from "../assets/images/login icon.png";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "./AuthProvider";

function Signup() {
  const { signup, error } = useUser();
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
    error: "",
  });
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const navigate = useNavigate();

  const togglePasswordVisibility = (field) => {
    setShowPassword((prevVisible) => ({
      ...prevVisible,
      [field]: !prevVisible[field],
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      error: "",
    });
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (
      !formData.userName ||
      !formData.email ||
      !formData.password ||
      !formData.confirmPassword
    ) {
      setFormData({
        ...formData,
        error: "All fields are mandatory",
      });
      return;
    }

    if (!passwordRegex.test(formData.password)) {
      setFormData({
        ...formData,
        error: "Password must be at least 8 characters long, include at least 1 uppercase, 1 lowercase, 1 number, and 1 special character",
      });
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setFormData({
        ...formData,
        error: "Passwords do not match",
      });
      return;
    }

    const signupData = {
      userName: formData.userName,
      email: formData.email,
      password: formData.password,
      confirmPassword: formData.confirmPassword,
    };

    const success = await signup(signupData);

    if (success) {
      setFormData({
        userName: "",
        email: "",
        password: "",
        confirmPassword: "",
        error: "",
      });
      navigate("/login");
    } else {
      setFormData({
        ...formData,
        error: error || "Something went wrong. Please try again.",
      });
    }
  };

  return (
    <div className="bg-img min-h-screen bg-cover bg-center md:pb-6 pb-[9vh]">
      <div
        className="flex flex-row justify-between w-full md:h-24 h-16 items-center px-4 lg:px-24 md:px-9"
        style={{ background: "#1b1919" }}
      >
        <div className="flex justify-center md:justify-start">
          <Link to="/">
            <img src={LOGO} className="h-16 md:h-36" alt="logo" />
          </Link>
        </div>
        <div className="flex flex-row md:gap-24 gap-4 pr-26 md:text-xl text-sm">
          <p
            className="Poppins text-white cursor-pointer"
            onClick={() => navigate("/")}
          >
            Home
          </p>
          <p
            className="Poppins text-white cursor-pointer"
            onClick={() => navigate("/login")}
          >
            Login
          </p>
          <p
            className="Poppins text-white cursor-pointer"
            onClick={() => navigate("/signup")}
          >
            Signup
          </p>
        </div>
      </div>

      <div className="flex items-center justify-center xl:mt-20 mt-[8vh]">
        <div className="bg-white xl:w-1/4 lg:w-[40%] md:w-2/3 md:mx-0 mx-4 py-6 rounded-2xl relative">
          <img
            src={LOGO_ICON}
            alt="Your Image"
            className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:w-24 md:h-24 h-20 w-20 object-cover rounded-full"
          />

          <h1 className="md:text-2xl text-1xl text-center font-bold arial px-9 tracking-tighter md:mt-16 mt-11">
            Sign up and get access to the full guide right now
          </h1>

          <form className="mt-4 px-12" onSubmit={handleSignup}>
            <input
              type="text"
              name="userName"
              className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none"
              placeholder="UserName"
              value={formData.userName}
              onChange={handleInputChange}
            />

            <input
              type="email"
              name="email"
              className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 mt-4 outline-none"
              placeholder="E-mail"
              value={formData.email}
              onChange={handleInputChange}
            />
            <div className="relative">
              <input
                type={showPassword.password ? "text" : "password"}
                name="password"
                className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 mt-4 outline-none"
                placeholder="Password"
                value={formData.password}
                onChange={handleInputChange}
              />
              <button
                type="button"
                className="absolute right-3 top-2/3 transform -translate-y-1/2"
                onClick={() => togglePasswordVisibility("password")}
              >
                {showPassword.password ? "👁️" : "👁️"}
              </button>
            </div>

            <div className="relative">
              <input
                type={showPassword.confirmPassword ? "text" : "password"}
                name="confirmPassword"
                className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 mt-4 outline-none"
                placeholder="Confirm Password"
                value={formData.confirmPassword}
                onChange={handleInputChange}
              />
              <button
                type="button"
                className="absolute right-3 top-2/3 transform -translate-y-1/2"
                onClick={() => togglePasswordVisibility("confirmPassword")}
              >
                {showPassword.confirmPassword ? "👁️" : "👁️"}
              </button>
            </div>
            {formData.error && (
              <p className="text-red-500 text-center mt-4">{formData.error}</p>
            )}

            <div className="flex justify-center mt-4 my-4">
              <button
                type="submit"
                style={{ background: "#000134", fontSize: "15px" }}
                className="w-full rounded-md py-2 text-white font-medium Poppins"
              >
                Signup
              </button>
            </div>
            <div className="text-center flex gap-2">
              Already Have An Account ?
              <Link to="/login" className="text-blue-500 underline">
                {" "}
                Login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Signup;
