import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LOGO from "../assets/images/logo.png";
import Profile from "../components/homescreen/Profile";
import "../dashboard/dashboard.css";
import Prediction from "./components/Prediction";
import Horoscope from "./components/Horoscope";
import Matching from "./components/Matching";
import Dashas from "./components/Dashas";
import Dosha from "./components/Dosh";
import Utility from "./components/Utility";
import DownloadPDF from "./components/DownloadPDF";
import { useUser } from "../authentication/AuthProvider";

function Dashboard() {
  const { isLoggedIn, loginUser } = useUser();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);

  let userStatus = null;

  if (Array.isArray(loginUser) && loginUser.length > 0) {
    userStatus = loginUser[0].status;
  } else if (loginUser && typeof loginUser === 'object') {
    userStatus = loginUser.status;
  }

  return (
    <div
      className={
        !isMobile
          ? "bgDB-img bg-fixed  min-h-screen bg-cover bg-center md:pb-6 pb-[9vh]"
          : "bgDBT-img bg-fixed  min-h-screen bg-cover bg-center md:pb-6 pb-[9vh]"
      }
    >
      <div
        className="flex flex-row justify-between h-16 md:h-24 items-center px-4 md:px-2 lg:px-24 sticky top-0 z-20"
        style={{ background: "rgb(13 25 73)" }}
      >
        <div className="flex justify-center md:justify-start">
          <Link to="/">
            <img src={LOGO} className="h-12 md:h-16 lg:h-36" alt="logo" />
          </Link>
        </div>
        <div className="flex flex-row gap-4 md:gap-8 lg:gap-24 text-sm md:text-base lg:text-xl text-white">
          {isLoggedIn ? (
            <>
              <Link to="/" className="Poppins text-white cursor-pointer">
                Home
              </Link>
            <Profile />
            </>
          ) : (
            <Link to="/login" className="Poppins cursor-pointer">
              Login
            </Link>
          )}
          {!isLoggedIn && (
            <Link to="/signup" className="Poppins cursor-pointer">
              Signup
            </Link>
          )}
        </div>
      </div>
      {loading ? (
        <p>Please wait loading</p>
      ) : (
        <div>
          {
            userStatus !== null && userStatus !== 0 ? (
              <div>
                <h1 className="sm:w-full lg:w-full text-white text-center mt-8 md:mt-12 lg:mt-5 text-2xl md:text-4xl lg:text-4xl px-4 md:px-9 lg:px-24 animate-slide-in font-mono font-bold">
                  Welcome to Your Destiny Finder
                </h1>
                <div>
                  <div className="w-full lg:flex-row lg:gap-8 items-start px-4 md:px-9 lg:px-24 lg:text-4xl sm:text-2xl">
                    <Prediction />
                    <Horoscope />
                    <Matching />
                    <Dashas />
                    <Dosha />
                    <Utility />
                    <DownloadPDF />
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center sm:w-full lg:w-1/2 mt-9">
                <h1 className="text-white mt-8 md:mt-12 lg:mt-8 text-2xl md:text-4xl lg:text-5xl px-4 md:px-9 lg:px-24 font-bold text-center animate-slide-in">
                  Please Choose a Plan
                </h1>
                <Link
                  to="/plans"
                  className="mt-8 px-6 py-3 text-lg md:text-xl lg:text-2xl font-semibold text-white-700 bg-red-50 shadow-lg shadow-red-500/50 hover:bg-gray-100 transition-colors duration-300 rounded-lg shadow-lg"
                >
                  Go To Plan Page
                </Link>
              </div>
            )
          }
        </div>
      )}
    </div>
  );
}

export default Dashboard;
