import React, { useEffect, useState } from "react";
import astrology from "../../assets/images/astrology.png";
import personal1 from "../../assets/images/personal1.png";
import planet from "../../assets/images/planet.png";
import daily from "../../assets/images/daily.png";
import weekly from "../../assets/images/weekly.png";
import yearly from "../../assets/images/yearly.png";
import smf from "../../assets/images/smf.png";
import numerology from "../../assets/images/numerology.png";
import axios from "axios";
import { useUser } from "../../authentication/AuthProvider";
import { BASE_URL } from "../../apiContstants";
// import jsPDF from "jspdf";

const cardData = [
  {
    id: 1,
    title: "Weekly-Sun",
    icon: weekly,
    Type: "Big",
    isLongTerm: false,
  },
  {
    id: 2,
    title: "Weekly-Moon",
    icon: weekly,
    Type: "Big",
    isLongTerm: false,
  },
  {
    id: 3,
    title: "Day-Number",
    icon: smf,
    isLongTerm: false,
  },
  {
    id: 4,
    title: "Daily-Moon",
    icon: personal1,
    Type: "small",
    isLongTerm: false,
  },
  {
    id: 5,
    title: "Daily-Sun",
    icon: daily,
    Type: "small",
    isLongTerm: false,
  },
  {
    id: 6,
    title: "Yearly",
    icon: yearly,
    isLongTerm: true,
  },
  {
    id: 7,
    title: "Biorhythm",
    icon: astrology,
    isLongTerm: true,
  },
  {
    id: 8,
    title: "Numerology",
    icon: numerology,
    isLongTerm: true,
  },
  {
    id: 9,
    title: "Daily-Nakshatra",
    icon: planet,
    isLongTerm: false,
  },
];

const zodiacSigns = {
  Aries: 1,
  Taurus: 2,
  Gemini: 3,
  Cancer: 4,
  Leo: 5,
  Virgo: 6,
  Libra: 7,
  Scorpio: 8,
  Sagittarius: 9,
  Capricorn: 10,
  Aquarius: 11,
  Pisces: 12,
};

const currentDate = new Date();
const formattedDate = currentDate.toLocaleDateString('en-GB', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
});

const getWeekOfMonth = (date) => {
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const pastDaysOfMonth = (date - firstDayOfMonth) / 86400000;
  return Math.ceil((pastDaysOfMonth + firstDayOfMonth.getDay() + 1) / 7);
};

const weekOfTheMonth = getWeekOfMonth(currentDate);

function Prediction() {
  const [selectedCard, setSelectedCard] = useState(null);
  const [isLongTerm, setIsLongTerm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { loginUser } = useUser();
  const [user, setUser] = useState({
    Date: formattedDate,
    Zodiac: null,
    show_same: true,
    split: true,
    Lang: null,
    Week: weekOfTheMonth,
    Year: new Date().getFullYear(),
    Dob: null,
  });
  const [responseData, setResponseData] = useState(null)
  const [expandedPhase, setExpandedPhase] = useState(null);

  const toggleAccordion = (phaseKey) => {
    setExpandedPhase(expandedPhase === phaseKey ? null : phaseKey);
  };

  // const handleDownload = () => {
  //   const doc = new jsPDF();
  
  //   // Locate the table element
  //   const table = document.querySelector('.matching-table');
  
  //   const pageWidth = doc.internal.pageSize.width; // Get the page width
  //   const margin = 5; // Define the left margin
  //   const rightMargin = 70; // Additional margin for the right side
  //   const usableWidth = pageWidth - margin - rightMargin;
  
  //   if (table) {
  //     // If table is found, generate PDF from table
  //     const options = {
  //       callback: function (doc) {
  //         doc.save('horoscope-data.pdf');
  //       },
  //       x: margin, // Adjust x position
  //       y: 10, // Adjust y position
  //       autoPaging: 'text', // Ensure content overflows to new pages
  //       html2canvas: {
  //         scale: 1, // Adjust scale for better clarity
  //       },
  //     };
  
  //     // Generate the PDF from the HTML table
  //     doc.html(table, options);
  //   } else if (typeof responseData === 'object') {
  //     // If table is not found and responseData exists, render data into PDF
  //     const renderDataToPDF = (data, x = margin, y = 20, fontSize = 12) => {
  //       doc.setFontSize(fontSize);
  
  //       if (y > doc.internal.pageSize.height - 20) {
  //         doc.addPage();
  //         y = 20; // Reset y position on new page
  //       }
  
  //       if (typeof data === 'object' && !Array.isArray(data)) {
  //         for (const key in data) {
  //           if (Object.prototype.hasOwnProperty.call(data, key)) {
  //             doc.text(`${key}:`, x, y); // Render the key
  //             y += 10;
  
  //             if (typeof data[key] === 'object') {
  //               // Handle nested objects recursively
  //               y = renderDataToPDF(data[key], x + 10, y, fontSize - 2);
  //             } else {
  //               const text = String(data[key]);
  //               const lines = doc.splitTextToSize(text, usableWidth); // Split text into lines
  //               lines.forEach((line) => {
  //                 if (y > doc.internal.pageSize.height - 20) {
  //                   doc.addPage();
  //                   y = 20;
  //                 }
  //                 doc.text(line, x + 40, y); // Render each line
  //                 y += 10;
  //               });
  //             }
  //           }
  //         }
  //       } else {
  //         // If it's not an object, render as plain text
  //         const text = String(data);
  //         const lines = doc.splitTextToSize(text, usableWidth);
  //         lines.forEach((line) => {
  //           if (y > doc.internal.pageSize.height - 20) {
  //             doc.addPage();
  //             y = 20;
  //           }
  //           doc.text(line, x, y);
  //           y += 10;
  //         });
  //       }
  
  //       return y;
  //     };
  
  //     // Start rendering data
  //     renderDataToPDF(responseData);
  
  //     // Save the PDF
  //     doc.save('horoscope-data.pdf');
  //   } else {
  //     console.error('No data found to generate the PDF.');
  //   }
  // };
  
  const userProfileId = Array.isArray(loginUser) && loginUser.length > 0
    ? loginUser[0].userProfileId
    : loginUser.userProfileId || '';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}api/userProfile/webhook/${userProfileId}`
        );
        const data = response.data;

        const zodiacNumber = zodiacSigns[data.zodiac] || null;

        setUser((prevUser) => ({
          ...prevUser,
          Zodiac: zodiacNumber,
          Lang: data.language,
          Dob: data.dateOfBirth,
        }));
      } catch (error) {
        console.log("fetching data: Wbhk");
      }
    };

    if (userProfileId) {
      fetchData();
    }
  }, [userProfileId]);

  const handleCardClick = async (index) => {
    const selectedCardId = cardData[index].id;
    const selectedCardTitle = cardData[index].title;
    const selectedCardType = cardData[index].Type;
    const isLongTerm = cardData[index].isLongTerm;
    setSelectedCard(index);
    setIsLongTerm(isLongTerm);

    const updatedUser = {
      ...user,
      Date: formattedDate,
      Week: weekOfTheMonth,
      Title: selectedCardTitle,
      Type: selectedCardType,
    };
    setUser(updatedUser);

    let requestData = {};

    switch (selectedCardId) {
      case 1:
      case 2:
        requestData = {
          cardId: selectedCardId,
          Date: user.Date,
          Week: user.Week,
          zodiac: user.Zodiac,
          show_same: user.show_same,
          split: user.split,
          type: updatedUser.Type,
          Lang: user.Lang,
          service: updatedUser.Title,
        };
        break;
      case 3:
        requestData = {
          cardId: selectedCardId,
          Date: user.Date,
          Lang: user.Lang,
          service: updatedUser.Title,
          show_same: user.show_same,
        };
        break;
      case 4:
      case 5:
        requestData = {
          cardId: selectedCardId,
          Date: user.Date,
          zodiac: user.Zodiac,
          show_same: user.show_same,
          split: user.split,
          type: updatedUser.Type,
          Lang: user.Lang,
          service: updatedUser.Title,
        };
        break;
      case 6:
        requestData = {
          cardId: selectedCardId,
          Year: user.Year,
          Zodiac: user.Zodiac,
          Lang: user.Lang,
          service: updatedUser.Title,
        };
        break;
      case 7:
        requestData = {
          cardId: selectedCardId,
          Dob: user.Dob,
          Lang: user.Lang,
          service: updatedUser.Title,
        };
        break;
      case 8:
        requestData = {
          cardId: selectedCardId,
          dob: updatedUser.Date,
          zodiacSign: updatedUser.Zodiac,
          year: updatedUser.Year,
          service: updatedUser.Title,
        };
        break;
      case 9:
        requestData = {
          cardId: selectedCardId,
          Date: user.Date,
          Nakshtra: user.Zodiac,
          show_same: user.show_same,
          Lang: user.Lang,
          service: updatedUser.Title,
        };
        break;
      default:
        return;
    }

    try {
      const response = await axios.post(
        `${BASE_URL}api/webhook/prediction/trigger?userProfileId=${userProfileId}&title=${selectedCardTitle}&isLongTerm=${isLongTerm}`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = response.data;
      // console.log(result)
      setResponseData(result)
    } catch (error) {
      console.log("Error: prdcn");
    }
  };

  const handleView = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="w-full sm:w-full lg:w-3/5">
      <div className="mt-5 flex-1">
        <h1 className="text-white font-mono">Prediction</h1>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-2 mt-5 font-mono">
          {cardData.map((card, cardIndex) => (
            <div className="" key={cardIndex}>
              <button
                className="w-full border rounded-lg mb-3 p-2 text-white cursor-pointer bg-black bg-opacity-40 lg:bg-opacity-35 sm:bg-opacity-40 shadow-lg shadow-cyan-500/50"
                onClick={() => handleCardClick(cardIndex)}
              >
                <div className="">
                  <div className="items-center">
                    <div className="circle-round">
                      <div className="icon-circle">
                        <img src={card.icon} alt="icon" className="h-6 w-6" />
                      </div>
                    </div>
                    <h5 className="sm:text-sm lg:text-xs xl:text-base p-1">
                      {card.title}
                    </h5>
                  </div>
                </div>
              </button>
              {selectedCard === cardIndex && (
                <div className="flex gap-4 mt-2">
                  <button
                    onClick={handleView}
                    className="text-xs p-2 bg-orange-500 text-white rounded-lg shadow-sm hover:bg-orange-700"
                  >
                    View Details
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {showModal && responseData && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white w-full lg:w-2/3 sm:w-11/12 p-4 rounded-lg shadow-lg mx-6">
            <h2 className="font-semibold mb-4">
              {Array.isArray(loginUser) && loginUser.length > 0
                ? loginUser[0].userName
                : loginUser.userName || ''} Horoscope
            </h2>

            <div className="w-full max-w-7xl max-h-96 overflow-y-auto">
              {
                isLongTerm ? (
                  Object.keys(responseData).map((phaseKey, index) => (
                    <div key={index}>
                      <button
                        className="w-full bg-blue-700 text-white text-sm sm:text-base md:text-base lg:text-lg xl:text-xl rounded-lg p-2 mt-9 cursor-pointer flex justify-between "
                        onClick={() => toggleAccordion(phaseKey)}
                      >
                        {phaseKey.toUpperCase()}
                        {expandedPhase === phaseKey ? (
                          <div>&#9650;</div>
                        ) : (
                          <div>&#9660;</div>
                        )}
                      </button>
                      {expandedPhase === phaseKey && (
                        <table className="w-full table-auto border-collapse mt-2">
                          <tbody>
                            {Object.keys(responseData[phaseKey]).map((categoryKey, idx) => {
                              const categoryData = responseData[phaseKey][categoryKey];
                              return (
                                <tr key={idx}>
                                  <td className="px-4 py-2 font-semibold text-lg">
                                    {categoryKey.toUpperCase()}
                                  </td>
                                  <td className="px-4 py-2 text-base">
                                    {typeof categoryData === 'object' ? (
                                      <table className="w-full table-auto mt-2">
                                        <tbody>
                                          {Object.keys(categoryData).map((subKey, subIdx) => (
                                            <tr key={subIdx}>
                                              <td className="px-4 py-1 font-semibold text-lg">{subKey}</td>
                                              <td className="px-4 py-1 text-sm">{categoryData[subKey]}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    ) : (
                                      <p>{categoryData}</p>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </div>

                  ))
                ) : (
                  <table className="w-full border-collapse matching-table">
                    <tbody>
                      {Object.entries(responseData).map(([key, value]) => (
                        <React.Fragment key={key}>
                          <tr className="border-b">
                            <td className="p-2 font-bold text-sm sm:text-base md:text-base lg:text-lg xl:text-xl">{key.replace(/_/g, ' ')}</td>
                            <td className="p-2 text-sm sm:text-base md:text-base lg:text-lg xl:text-xl">
                              {typeof value === 'string' ? (
                                value
                              ) : (
                                <table className="w-full">
                                  <tbody>
                                    {Object.entries(value).map(([subKey, subValue]) => (
                                      <tr key={subKey}>
                                        <td className="p-2 font-semibold">{subKey}</td>
                                        <td className="p-2">{subValue}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                )
              }
            </div>

            {/* <button
              onClick={handleDownload}
              className="mt-4 p-3 bg-red-500 text-white text-sm rounded-lg shadow-sm hover:bg-red-700"
            >
              Download
            </button> */}

            <button
              onClick={closeModal}
              className="mt-4 p-3 bg-red-500 text-white text-sm rounded-lg shadow-sm hover:bg-red-700 float-right"
            >
              Close
            </button>
          </div>
        </div>
      )}

    </div>
  );
}

export default Prediction;
