import React, { useEffect, useState } from "react";
import ListOfPlanTypes from "./ListOfPlanTypes";
import "./cardDetails.css";
import { useAllPlans } from "./CardContext";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/AuthContext";
import { BASE_URL } from "../../../apiContstants";

function CardDetails() {
  const { isLoggedInAdmin } = useAuth()
  const [cardData, setCardData] = useState({
    Card: { planId: 1, basePrice: "", discount: "", offerPrice: "", planValid: "" },
    Web: { planId: 2, basePrice: "", discount: "", offerPrice: "", planValid: "" },
    "Card and Web": { planId: 3, basePrice: "", discount: "", offerPrice: "", planValid: "" },
  });
  const { allPlans } = useAllPlans()
  const [activeSection, setActiveSection] = useState(null);
  const navigate = useNavigate()

  useEffect(() => {
    const isLoggedInAdmin = localStorage.getItem("isLoggedInAdmin")
    if (!isLoggedInAdmin) {
      navigate("/admin/login")
    }
  }, [isLoggedInAdmin, navigate])

  const calculateOfferPrice = (basePrice, discount) => {
    if (basePrice && discount) {
      const base = parseFloat(basePrice);
      const disc = parseFloat(discount);
      return Math.round((base - base * (disc / 100)).toFixed(2));
    }
    return "";
  };

  const handleInputChange = (e, type, field) => {
    const value = e.target.value;

    setCardData((prevState) => {
      const updatedData = {
        ...prevState,
        [type]: {
          ...prevState[type],
          [field]: value,
        },
      };

      if (field === "basePrice" || field === "discount") {
        const basePrice = updatedData[type].basePrice;
        const discount = updatedData[type].discount;
        const offerPrice = calculateOfferPrice(basePrice, discount);

        updatedData[type].offerPrice = offerPrice;
      }

      return updatedData;
    });
  };

  const handleSubmit = async (e, type) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("validity", cardData[type].planValid);
      formData.append("planTypeId", cardData[type].planId);
      formData.append("planType", type);
      formData.append("discountPercentage", cardData[type].discount);
      formData.append("actualPrice", cardData[type].basePrice);

      // for (const pair of formData.entries()) {
      //   console.log(`${pair[0]}: ${pair[1]}`);
      // }

      const response = await fetch(`${BASE_URL}api/plans/savePlan`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        alert(`${type} details updated successfully!`);
        window.location.reload();
      } else {
        alert(`Failed to update ${type} details`);
      }
    } catch (error) {
      console.error(`Error updating ${type} details:`, error);
      alert(`An error occurred while updating ${type} details`);
    }
  };

  const isPlanExisting = (planId) => {
    return allPlans.some((plan) => plan.planTypeId === planId);
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };


  return (
    <div className="w-full lg:w-9/12 md:w-9/12 sm:w-11/12 mt-11 mb-11">
      <h3 className="text-white text-center">Manage Card Detail</h3>
      <section className="flex flex-col md:flex-row gap-3 card-section justify-evenly mt-4 mb-3">
        {/* Card */}
        <div className="col-span-12 md:col-span-4 mb-3">
          <div className="bg-gray-100 text-gray-800 w-full shadow-md rounded">
            <strong className="block text-xl bg-sky-300 p-2 rounded-t">Card</strong>
            <div className="p-4">
              <select
                id="filter"
                className="w-full border border-gray-300 rounded p-2 mb-2"
                value={cardData.Card.planValid}
                onChange={(e) => {
                  handleInputChange(e, "Card", "planValid");
                  handleSectionChange("Card");
                }}
                disabled={isPlanExisting(cardData.Card.planId) || activeSection && activeSection !== "Card"}
              >
                <option value="">Choose Plan Validity</option>
                <option value="lifetime">Lifetime</option>
                <option value="yearly" disabled>Yearly</option>
                <option value="monthly" disabled>Monthly</option>
              </select>
              <input
                type="number"
                className="border border-gray-300 rounded p-2 mb-2 w-full"
                placeholder="Enter Base Price"
                value={cardData.Card.basePrice}
                min="0"
                onChange={(e) => {
                  handleInputChange(e, "Card", "basePrice");
                  handleSectionChange("Card");
                }}
                disabled={isPlanExisting(cardData.Card.planId) || activeSection && activeSection !== "Card"}
              />
              <input
                type="number"
                className="border border-gray-300 rounded p-2 mb-2 w-full"
                placeholder="Enter Discount (%)"
                value={cardData.Card.discount}
                min="0"
                onChange={(e) => {
                  handleInputChange(e, "Card", "discount");
                  handleSectionChange("Card");
                }}
                disabled={isPlanExisting(cardData.Card.planId) || activeSection && activeSection !== "Card"}
              />
              <input
                type="number"
                className="border border-gray-300 rounded p-2 mb-2 w-full"
                placeholder="Offer Price"
                value={cardData.Card.offerPrice}
                min="0"
                readOnly
              />
              <button
                className="bg-blue-500 text-white rounded py-2 px-4 mt-3 hover:bg-blue-600"
                onClick={(e) => handleSubmit(e, "Card")}
                disabled={isPlanExisting(cardData.Card.planId) || activeSection && activeSection !== "Card"}
              >
                Save Plan
              </button>
            </div>
          </div>
        </div>

        {/* Web */}
        <div className="col-span-12 md:col-span-4 mb-3">
          <div className="bg-gray-100 text-gray-800 w-full shadow-md rounded">
            <strong className="block text-xl bg-sky-300 p-2 rounded-t">Web</strong>
            <div className="p-4">
              <select
                id="filter"
                className="w-full border border-gray-300 rounded p-2 mb-2"
                value={cardData.Web.planValid}
                onChange={(e) => {
                  handleInputChange(e, "Web", "planValid");
                  handleSectionChange("Web");
                }}
                disabled={isPlanExisting(cardData.Web.planId) || activeSection && activeSection !== "Web"}
              >
                <option value="">Choose Plan Validity</option>
                <option value="lifetime">Lifetime</option>
                <option value="yearly" disabled>Yearly</option>
                <option value="monthly" disabled>Monthly</option>
              </select>
              <input
                type="number"
                className="border border-gray-300 rounded p-2 mb-2 w-full"
                placeholder="Enter Base Price"
                value={cardData.Web.basePrice}
                min="0"
                onChange={(e) => {
                  handleInputChange(e, "Web", "basePrice");
                  handleSectionChange("Web");
                }}
                disabled={isPlanExisting(cardData.Web.planId) || activeSection && activeSection !== "Web"}
              />
              <input
                type="number"
                className="border border-gray-300 rounded p-2 mb-2 w-full"
                placeholder="Enter Discount (%)"
                value={cardData.Web.discount}
                min="0"
                onChange={(e) => {
                  handleInputChange(e, "Web", "discount");
                  handleSectionChange("Web");
                }}
                disabled={isPlanExisting(cardData.Web.planId) || activeSection && activeSection !== "Web"}
              />
              <input
                type="number"
                className="border border-gray-300 rounded p-2 mb-2 w-full"
                placeholder="Offer Price"
                value={cardData.Web.offerPrice}
                min="0"
                readOnly
              />
              <button
                className="bg-blue-500 text-white rounded py-2 px-4 mt-3 hover:bg-blue-600"
                onClick={(e) => handleSubmit(e, "Web")}
                disabled={isPlanExisting(cardData.Web.planId) || activeSection && activeSection !== "Web"}
              >
                Save Plan
              </button>
            </div>
          </div>
        </div>

        {/* Card and Web */}
        <div className="col-span-12 md:col-span-5 mb-3">
          <div className="bg-gray-100 text-gray-800 w-full shadow-md rounded">
            <div className="bg-sky-300 text-xl p-2 rounded-t">
              <strong>Card & Web</strong>
            </div>
            <div className="p-4">
              <select
                id="filter"
                className="w-full border border-gray-300 rounded p-2 mb-2"
                value={cardData["Card and Web"].planValid}
                onChange={(e) => {
                  handleInputChange(e, "Card and Web", "planValid");
                  handleSectionChange("Card and Web");
                }}
                disabled={isPlanExisting(cardData["Card and Web"].planId) || activeSection && activeSection !== "Card and Web"}
              >
                <option value="">Choose Plan Validity</option>
                <option value="lifetime">Lifetime</option>
                <option value="yearly" disabled>Yearly</option>
                <option value="monthly" disabled>Monthly</option>
              </select>
              <input
                type="number"
                className="border border-gray-300 rounded p-2 mb-2 w-full"
                placeholder="Enter Base Price"
                value={cardData["Card and Web"].basePrice}
                min="0"
                onChange={(e) => {
                  handleInputChange(e, "Card and Web", "basePrice");
                  handleSectionChange("Card and Web");
                }}
                disabled={isPlanExisting(cardData["Card and Web"].planId) || activeSection && activeSection !== "Card and Web"}
              />
              <input
                type="number"
                className="border border-gray-300 rounded p-2 mb-2 w-full"
                placeholder="Enter Discount (%)"
                value={cardData["Card and Web"].discount}
                min="0"
                onChange={(e) => {
                  handleInputChange(e, "Card and Web", "discount");
                  handleSectionChange("Card and Web");
                }}
                disabled={isPlanExisting(cardData["Card and Web"].planId) || activeSection && activeSection !== "Card and Web"}
              />
              <input
                type="number"
                className="border border-gray-300 rounded p-2 mb-2 w-full"
                placeholder="Offer Price"
                value={cardData["Card and Web"].offerPrice}
                min="0"
                readOnly
              />
              <button
                className="bg-blue-500 text-white rounded py-2 px-4 mt-3 hover:bg-blue-600"
                onClick={(e) => handleSubmit(e, "Card and Web")}
                disabled={isPlanExisting(cardData["Card and Web"].planId) || activeSection && activeSection !== "Card and Web"}
              >
                Save Plan
              </button>
            </div>
          </div>
        </div>
      </section>
      <ListOfPlanTypes />
    </div>


  );
}

export default CardDetails;
