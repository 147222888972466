import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useUser } from "../../authentication/AuthProvider";
import { BASE_URL } from "../../apiContstants";

const rzp_key = "rzp_test_P7eTEWTbR1y2Sm";

function Premium({ planValidity }) {
  const { isLoggedIn, loginUser } = useUser();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const responsePlan = await axios.get(
          `${BASE_URL}api/plans/fetchByWebCardPrice`
        );
        const plans = responsePlan.data;
        setPlans(plans);
        // localStorage.setItem("plans", JSON.stringify(plans))
      } catch (error) {
        console.log("Error in responsepremium");
      }
    };
    fetchPlans();
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  let userProfileId = null;

  if (Array.isArray(loginUser) && loginUser.length > 0) {
    userProfileId = loginUser[0].userProfileId;
  } else if (loginUser && typeof loginUser === "object") {
    userProfileId = loginUser.userProfileId;
  }

  const handleBuy = (plans) => {
    if (isLoggedIn || Array.isArray(planType)) {
      if (planType.includes("Card")) {
        handlePayment();
      } else {
        navigate("/shipadd", { state: { plans } });
      }
    } else {
      navigate("/signup");
    }
  };

  const handlePayment = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("paymentAmount", plans.offerPrice);
      formData.append("userProfile", loginUser.userProfileId);
      formData.append("planType", plans.planType);
      formData.append("planTypeId", plans.planId);
      formData.append("validity", planValidity);

      const { data: order } = await axios.post(
        `${BASE_URL}api/payments/savePayment/${userProfileId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // console.log('Order received:', order);

      const options = {
        key: rzp_key,
        amount: order.paymentAmount,
        currency: "INR",
        name: "Techpixe",
        description: "Payment for test",
        image: "https://your-logo-url.com",
        order_id: order.razorPayOrderId,
        handler: async (response) => {
          // console.log(response)
          const paymentDetails = {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          };

          try {
            const paymentResponse = await axios.post(
              `${BASE_URL}api/payments/verifyPayment`,
              paymentDetails
            );
            // console.log('Payment response from API:', paymentResponse);
            localStorage.setItem("planType", plans.planType);

            if (Array.isArray(loginUser)) {
              loginUser.push({ planType: plans.planType });
            } else if (typeof loginUser === "object") {
              loginUser.planType = plans.planType;
            }

            if (order.userDataExists !== true) {
              navigate("/customerData");
            } else {
              navigate("/");
            }
            alert(
              "Payment processed successfully: " +
                JSON.stringify(paymentResponse.data)
            );
          } catch (apiError) {
            // console.error("Error sending payment response to API:", apiError);
            alert(apiError.response.data);
          }
        },
        prefill: {
          name: loginUser.firstName,
          email: loginUser.email,
          contact: loginUser.mobileNumber,
        },
        theme: { color: "#00008B" },
      };
      const rzp1 = new window.Razorpay(options);

      rzp1.on("payment failed", function (response) {
        alert(response.error.description);
        // console.error(response.error);
      });
      rzp1.open();
      localStorage.setItem("planType", plans.planType);
    } catch (error) {
      // console.error("Error during payment process:", error);
      alert("There was an error processing your payment. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const storedPlanType = localStorage.getItem("planType");

  let planType = null;
  if (
    storedPlanType &&
    storedPlanType.startsWith("[") &&
    storedPlanType.endsWith("]")
  ) {
    planType = JSON.parse(storedPlanType);
  } else {
    planType = storedPlanType;
  }

  let hasCard = false;
  let hasWeb = false;
  let hasCardAndWeb = false;

  if (Array.isArray(planType)) {
    hasCard = planType.includes("Card");
    hasWeb = planType.includes("Web");
    hasCardAndWeb = planType.includes("Card and Web");
  } else if (planType && typeof planType === "string") {
    hasCard = planType === "Card";
    hasWeb = planType === "Web";
    hasCardAndWeb = planType === "Card and Web";
  }
  let hasNoPlans = false;

  if (Array.isArray(loginUser)) {
    hasNoPlans = loginUser.every((user) => !user.planType);
  } else if (loginUser && typeof loginUser.planType === "string") {
    hasNoPlans = !loginUser.planType;
  } else {
    hasNoPlans = true;
  }

  return (
    <div className="relative flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white animate-slide-in shadow-lg shadow-cyan-300/50">
      <h3 className="mb-4 mt-2 text-2xl font-bold">{plans.planType}</h3>
      {hasCardAndWeb ? (
        <div className="absolute top-2 left-2 animate-shake">
          <p className="text-xs font-bold bg-blue-600 text-white p-2 rounded-full">
            <span>Active</span>
          </p>
        </div>
      ) : null}
      <div className="absolute top-0 right-0">
        <p className="text-xs font-bold bg-orange-400 p-2 rounded-full">
          <span>{plans.discountPercentage}% </span>Off
        </p>
      </div>
      <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
        Get the Premium Access
      </p>
      <div>
        {plans.discountPercentage ? (
          <div>
            <p className="text-1xl font-bold">
              Base Price - ₹<s>{plans.actualPrice}</s>
            </p>
            <div className="my-8">
              <span className="mr-2 text-1xl font-bold bg-green-600 p-2 rounded text-stone-50">
                Offer Price
              </span>
              <div className="flex justify-center items-baseline mt-2 lg:mt-9">
                <strong className="text-3xl">₹</strong>
                <span className="mr-2 text-5xl lg:text-3xl xl:text-4xl font-extrabold text-blue-500">
                  {plans.offerPrice}
                </span>
                <span className="text-gray-500 dark:text-gray-400">
                  /{plans.validity}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <p className="text-1xl font-bold">No Offers Available</p>
            <div className="my-8">
              <span className="mr-2 text-1xl font-bold bg-green-600 p-2 rounded text-stone-50">
                Price
              </span>
              <div className="flex justify-center items-baseline mt-2 lg:mt-9">
                <strong className="text-3xl">₹</strong>
                <span className="mr-2 text-5xl lg:text-3xl xl:text-4xl font-extrabold text-blue-500">
                  {plans.actualPrice}
                </span>
                <span className="text-gray-500 dark:text-gray-400">
                  /{plans.validity}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      <ul role="list" className="mb-4 space-y-4 text-left">
        <li className="flex items-center space-x-3">
          <svg
            className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>Planet Report</span>
        </li>
        <li className="flex items-center space-x-3">
          <svg
            className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>Personal Characteristics</span>
        </li>
        <li className="flex items-center space-x-3">
          <svg
            className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>Daily Sun</span>
        </li>
        <li className="flex items-center space-x-3">
          <svg
            className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>Weekly Sun</span>
        </li>
        <li className="flex items-center space-x-3">
          <svg
            className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>Yearly</span>
        </li>
        <li className="flex items-center space-x-3">
          <svg
            className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>Planet Details</span>
        </li>
        <li className="flex items-center space-x-3">
          <svg
            className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>Day Number</span>
        </li>
        <li className="flex items-center space-x-3">
          <svg
            className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>Prediction</span>
        </li>
        <li className="flex items-center space-x-3">
          <svg
            className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>Horoscope</span>
        </li>
        <li className="flex items-center space-x-3">
          <svg
            className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>Matching</span>
        </li>
        <li className="flex items-center space-x-3">
          <svg
            className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>Dashas</span>
        </li>
        <li className="flex items-center space-x-3">
          <svg
            className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>Dosh</span>
        </li>
        <li className="flex items-center space-x-3">
          <svg
            className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>Utility</span>
        </li>
      </ul>

      <button
        onClick={() => handleBuy(plans)}
        className={`mb-11 text-white font-bold py-2 px-4 rounded animate-slide-in shadow-lg 
                    ${
                      hasCardAndWeb
                        ? "bg-yellow-500 cursor-not-allowed"
                        : hasWeb && hasCard
                        ? "bg-yellow-500 cursor-not-allowed"
                        : "bg-blue-600 hover:bg-blue-500"
                    } 
                    ${hasCardAndWeb ? "" : "hover:shadow-cyan-500/50"}`}
        disabled={hasCardAndWeb || (hasWeb && hasCard)}
      >
        {!hasNoPlans ? "Upgrade Your Plan" : "Buy Now"}
      </button>
    </div>
  );
}

export default Premium;
