import React, { useEffect, useState } from "react";
import LOGO from "../../assets/images/logo.png";
import LOGO_ICON from "../../assets/images/login icon.png";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../../authentication/AuthProvider";
import Profile from "./Profile";
import { BASE_URL } from "../../apiContstants";

function UpdateProfile() {
  const [updProfile, setUpdProfile] = useState({
    mobileNumber: "",
    userName: "",
    uploadImage: "",
    language: "",
  });
  const [error, setError] = useState("");
  const { loginUser, setLoginUser, isLoggedIn } = useUser();
  const navigate = useNavigate();

  const userProfileId =
    Array.isArray(loginUser) && loginUser.length > 0
      ? loginUser[0].userProfileId
      : loginUser?.userProfileId || "";

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    const file = files ? files[0] : null;

    if (name === "mobileNumber") {
      if (/^\d{0,10}$/.test(value)) {
        setUpdProfile((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else if (name === "uploadImage") {
      if (file) {
        const validTypes = ["image/jpeg", "image/png", "image/jpg"];
        const maxSize = 2 * 1024 * 1024; // 2MB

        if (!validTypes.includes(file.type)) {
          alert("Please upload an image in JPG, JPEG, or PNG format.");
          return;
        }
        if (file.size > maxSize) {
          alert("File size should be less than 2MB.");
          return;
        }

        setUpdProfile((prev) => ({
          ...prev,
          uploadImage: file,
        }));
      }
    } else {
      setUpdProfile((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const isAnyFieldFilled = Object.values(updProfile).some(
      (value) => value !== ""
    );

    if (!isAnyFieldFilled) {
      setError("Please fill at least one field before updating.");
      return;
    }

    setError("");

    try {
      const formData = new FormData();
      formData.append("userProfileId", userProfileId);

      if (updProfile.userName) formData.append("userName", updProfile.userName);
      if (updProfile.mobileNumber)
        formData.append("mobileNumber", updProfile.mobileNumber);
      if (updProfile.uploadImage)
        formData.append("uploadImage", updProfile.uploadImage);
      if (updProfile.language) formData.append("language", updProfile.language);

      const response = await fetch(
        `${BASE_URL}api/userProfile/update2/${userProfileId}`,
        {
          method: "PUT",
          body: formData,
        }
      );

      if (response.ok) {
        const updatedUserData = await response.json();
        setLoginUser(updatedUserData);
        navigate("/");
      } else {
        setError("Update failed. Please try again.");
      }
    } catch (err) {
      setError("Update failed. Please try again.");
    }
  };

  const planType = localStorage.getItem("planType");
  const firstName =
    loginUser && Array.isArray(loginUser) && loginUser.length > 0
      ? loginUser[0].firstName
      : loginUser?.firstName;

  return (
    <div
      style={{
        backgroundColor: "#0f1d51fa",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <div
        className="flex flex-row justify-between md:h-24 h-16 items-center px-4 lg:px-24 md:px-9"
        style={{ background: "rgb(13 25 73)" }}
      >
        <div className="flex justify-center md:justify-start">
          <Link to="/">
            <img src={LOGO} className="h-16 md:h-36" alt="logo" />
          </Link>
        </div>
        <div className="flex flex-row md:gap-24 gap-4 md:pr-26 md:text-xl text-sm">
          {isLoggedIn ? (
            <>
              <Link to="/" className="Poppins text-white cursor-pointer">
                Home
              </Link>
              {firstName !== null &&
                (planType.includes("Web") ||
                  planType.includes("Card and Web")) && (
                  <dl>
                    <Link
                      to="/dashboard"
                      className="Poppins text-white cursor-pointer"
                    >
                      Dashboard
                    </Link>
                  </dl>
                )}
              <Profile />
            </>
          ) : (
            <Link to="/login" className="Poppins text-white cursor-pointer">
              Login
            </Link>
          )}
          {!isLoggedIn && (
            <Link to="/signup" className="Poppins text-white cursor-pointer">
              Signup
            </Link>
          )}
        </div>
      </div>
      <div
        className="flex items-center md:h-3/4 justify-center"
        style={{ marginTop: "7rem" }}
      >
        <div className="bg-white xl:w-[38%] lg:w-[40%] md:w-2/3 md:mx-0 mx-4 py-6 rounded-2xl relative">
          <img
            src={LOGO_ICON}
            alt="Your Image"
            className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:w-24 md:h-24 h-20 w-20 object-cover rounded-full"
          />

          <h1 className="md:text-2xl text-1xl text-center font-bold arial px-9 tracking-tighter md:mt-16 mt-11">
            Your Details
          </h1>

          {error && (
            <div className="text-red-500 text-center mb-4">{error}</div>
          )}

          <form className="mt-4 px-12" onSubmit={handleUpdate}>
            <div className="flex flex-wrap -mx-2">
              <div className="w-full px-2 mb-4">
                <label>
                  User Name
                  <input
                    type="text"
                    name="userName"
                    className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none"
                    placeholder="Username"
                    value={updProfile.userName}
                    onChange={handleInputChange}
                  />
                </label>
              </div>
            </div>

            <div className="mb-4">
              <label>
                Mobile Number
                <input
                  type="number"
                  name="mobileNumber"
                  className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none"
                  placeholder="Mobile Number"
                  value={updProfile.mobileNumber}
                  min="0"
                  onChange={handleInputChange}
                />
              </label>
            </div>

            <div className="w-full mb-4">
              Update Profile Photo
              <label>
                <input
                  type="file"
                  name="uploadImage"
                  className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none"
                  onChange={handleInputChange}
                />
              </label>
            </div>

            <label>
              Selected Language
              <br />
              <select
                name="language"
                onChange={handleInputChange}
                value={updProfile.language}
                className="arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none"
              >
                {/* <option value="" disabled>Select Language</option> */}
                <option value="en">English</option>
                {/* <option value="be">Bengali (Bangla)</option>
                <option value="hi">Hindi</option>
                <option value="ka">Kannada</option>
                <option value="ml">Malayalam</option>
                <option value="ta">Tamil</option>
                <option value="te">Telugu</option>
                <option value="sp">Spanish</option>
                <option value="fr">French</option> */}
              </select>
            </label>

            <div className="flex justify-center mt-8">
              <button
                type="submit"
                className="w-full rounded-md py-2 text-white font-medium Poppins bg-orange-500 hover:bg-orange-600"
              >
                Update Profile
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UpdateProfile;
