import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LOGO from "../../assets/images/logo.png";
import { useUser } from "../../authentication/AuthProvider";
import axios from "axios";
import Profile from "../homescreen/Profile";
import { BASE_URL } from "../../apiContstants";
import loader from "../../assets/images/loader1.gif";

const statesList = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Lakshadweep",
  "Delhi",
  "Puducherry",
];

const rzp_key = "rzp_test_P7eTEWTbR1y2Sm";

function ShippingAddress() {
  const { isLoggedIn, loginUser } = useUser();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    shipping_email: "",
    shipping_customer_name: "",
    shipping_last_name: "",
    shipping_phone: "",
    shipping_pincode: "",
    shipping_address: "",
    shipping_city: "",
    shipping_state: "",
    shipping_country: "India",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [grandTotal, setGrandTotal] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [order, setOrder] = useState(null);
  const location = useLocation();
  const { plans } = location.state || {};

  const planValidity = localStorage.getItem("planValidity");

  // console.log(plans)

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);

  let userProfileId = null;

  if (Array.isArray(loginUser) && loginUser.length > 0) {
    userProfileId = loginUser[0].userProfileId;
  } else if (loginUser && typeof loginUser === "object") {
    userProfileId = loginUser.userProfileId;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    const {
      shipping_email,
      shipping_customer_name,
      shipping_last_name,
      shipping_phone,
      shipping_pincode,
      shipping_address,
      shipping_city,
      shipping_state,
    } = formData;
    if (
      !shipping_email ||
      !shipping_customer_name ||
      !shipping_last_name ||
      !shipping_phone ||
      !shipping_pincode ||
      !shipping_address ||
      !shipping_city ||
      !shipping_state
    ) {
      alert("Please fill out all are required fields.");
      return false;
    }
    return true;
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setModalOpen(true);
  };

  const handleModalResponse = async (response) => {
    setModalOpen(false);
    if (response === "yes") {
      setLoading(true);
      try {
        const userProfileId =
          Array.isArray(loginUser) && loginUser.length > 0
            ? loginUser[0].userProfileId
            : loginUser.userProfileId || "";

        const planAmount = plans.offerPrice;
        // console.log(planAmount);
        const orderResponse = await axios.post(
          `${BASE_URL}api/shiprocket/createCardOrder/${userProfileId}/${planAmount}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        // console.log(orderResponse);
        alert("Address saved successfully!");
        localStorage.setItem(
          "shipping_customer_name",
          orderResponse.data.shipping_customer_name
        );
        const totalFrightCharges = orderResponse.data.frightCharges;
        const frightCharges = Math.round(totalFrightCharges);
        const grandTotal = frightCharges + plans.offerPrice;
        setGrandTotal(grandTotal);
        setShippingCharge(frightCharges);
        setOrder(orderResponse.data);
        await setShowModal(true);
      } catch (error) {
        console.error("Error during the process:", error);
        if(error.response.status === 350){
          alert("Please try again, delivery partners are not available at this time.");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCloseModal = async () => {
    try {
      const shiprocketOrderId = order.shiprocketOrderId;

      const formData = new FormData();
      formData.append("shiprocketOrderId", shiprocketOrderId);

      await axios.delete(`${BASE_URL}api/shiprocket/deleteShipmentOrder`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });
      navigate("/");
    } catch (error) {
      console.error("Error deleting the order:", error);
    }
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setShowModal(false)
    setLoading(true);
    await handlePayment();
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handlePayment = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("paymentAmount", grandTotal);
      formData.append("planType", plans.planType);
      formData.append("planTypeId", plans.planTypeId);
      formData.append("validity", planValidity);

      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }

      const { data: order } = await axios.post(
        `${BASE_URL}api/payments/savePayment/${userProfileId}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      // console.log(order)

      const options = {
        key: rzp_key,
        amount: order.paymentAmount,
        currency: "INR",
        name: "Techpixe",
        description: "Payment for test",
        image: "https://your-logo-url.com",
        order_id: order.razorPayOrderId,
        handler: async (response) => {
          // console.log(response)
          const paymentDetails = {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          };

          try {
            const paymentResponse = await axios.post(
              `${BASE_URL}api/payments/verifyPayment`,
              paymentDetails
            );

            // console.log('Payment response from API:', paymentResponse);
            localStorage.setItem("planType", plans.planType);

            if (Array.isArray(loginUser)) {
              loginUser.push({ planType: plans.planType });
            } else if (typeof loginUser === "object") {
              loginUser.planType = plans.planType;
            }

            if (order.userDataExists !== true) {
              navigate("/customerData");
            } else {
              navigate("/");
            }

            alert(JSON.stringify(paymentResponse.data));
          } catch (apiError) {
            console.error("Error sending payment response to API:", apiError);
            alert(apiError.response.data);
          }
        },
        prefill: {
          name: loginUser.firstName,
          email: loginUser.email,
          contact: loginUser.mobileNumber,
        },
        theme: { color: "#00008B" },
      };
      // console.log(options);
      const rzp1 = new window.Razorpay(options);

      rzp1.on("payment.failed", async function (response) {
        alert(response.error.description);
        console.error(response.error);
        const shiprocketOrderId = order.shiprocketOrderId;

        const formData = new FormData();
        formData.append("shiprocketOrderId", shiprocketOrderId);

        await axios.delete(`${BASE_URL}api/shiprocket/deleteShipmentOrder`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        });
        navigate("/");
      });
      rzp1.open();
    } catch (error) {
      console.error("Error during payment process:", error);
      alert("There was an error processing your payment. Please try again.");
      const shiprocketOrderId = order.shiprocketOrderId;

      const formData = new FormData();
      formData.append("shiprocketOrderId", shiprocketOrderId);

      await axios.delete(`${BASE_URL}api/shiprocket/deleteShipmentOrder`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });
      navigate("/");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-img min-h-screen bg-cover bg-center md:pb-6 pb-[9vh]">
      <div
        className="flex flex-row justify-between h-16 md:h-24 items-center px-4 md:px-2 lg:px-24 sticky top-0 z-20 mb-8"
        style={{ background: "rgb(13 25 73)" }}
      >
        <div className="flex justify-center md:justify-start">
          <Link to="/">
            <img src={LOGO} className="h-12 md:h-16 lg:h-36" alt="logo" />
          </Link>
        </div>
        <div className="flex flex-row gap-4 md:gap-8 lg:gap-24 text-sm md:text-base lg:text-xl text-white">
          {isLoggedIn ? (
            <Profile />
          ) : (
            <>
              <Link to="/login" className="Poppins cursor-pointer">
                Login
              </Link>
              <Link to="/signup" className="Poppins cursor-pointer">
                Signup
              </Link>
            </>
          )}
        </div>
      </div>

      {/* Form */}
      {loading ? (
        <div className="flex justify-center items-center w-full h-full">
          <img src={loader} alt="loader" />
        </div>
      ) : (
        <div className="flex items-center justify-center m-5">
          <div className="max-w-6xl w-full bg-white rounded-lg shadow-lg overflow-hidden flex flex-col md:flex-row">
            <div className="w-full p-10">
              <h2 className="text-2xl font-semibold mb-5">
                Add Shipping Address
              </h2>
              <form className="space-y-5" onSubmit={handleSaveClick} noValidate>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                  <div>
                    <label className="block text-gray-700 font-semibold">
                      E-mail
                    </label>
                    <input
                      type="e-mail"
                      name="shipping_email"
                      placeholder="Enter E-mail Id"
                      value={formData.shipping_email}
                      onChange={handleChange}
                      className={`w-full p-2 border ${
                        errors.shipping_email
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded`}
                    />
                    {errors.shipping_email && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.shipping_email}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-gray-700 font-semibold">
                      Phone Number
                    </label>
                    <input
                      type="number"
                      name="shipping_phone"
                      placeholder="Enter Phone Number"
                      pattern="[0-9]{10}"
                      maxLength="10"
                      value={formData.shipping_phone}
                      min="0"
                      onChange={handleChange}
                      className={`w-full p-2 border ${
                        errors.shipping_phone
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded`}
                    />
                    {errors.shipping_phone && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.shipping_phone}
                      </p>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                  <div>
                    <label className="block text-gray-700 font-semibold">
                      First Name
                    </label>
                    <input
                      type="text"
                      name="shipping_customer_name"
                      placeholder="Enter First Name"
                      value={formData.shipping_customer_name}
                      onChange={handleChange}
                      className={`w-full p-2 border ${
                        errors.shipping_customer_name
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded`}
                    />
                    {errors.shipping_customer_name && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.shipping_customer_name}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-gray-700 font-semibold">
                      Last Name
                    </label>
                    <input
                      type="text"
                      name="shipping_last_name"
                      placeholder="Enter Last Name"
                      value={formData.shipping_last_name}
                      onChange={handleChange}
                      className={`w-full p-2 border ${
                        errors.shipping_last_name
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded`}
                    />
                    {errors.shipping_last_name && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.shipping_last_name}
                      </p>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                  <div>
                    <label className="block text-gray-700 font-semibold">
                      Address
                    </label>
                    <textarea
                      name="shipping_address"
                      placeholder="ex: house no,street name"
                      value={formData.shipping_address}
                      onChange={handleChange}
                      className={`w-full p-2 border ${
                        errors.shipping_address
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded`}
                    />
                    {errors.shipping_address && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.shipping_address}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-gray-700 font-semibold">
                      City/District/Town
                    </label>
                    <input
                      type="text"
                      name="shipping_city"
                      placeholder="City/District/Town"
                      value={formData.shipping_city}
                      onChange={handleChange}
                      className={`w-full p-2 border ${
                        errors.shipping_city
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded`}
                    />
                    {errors.shipping_city && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.shipping_city}
                      </p>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
                  <div>
                    <label className="block text-gray-700 font-semibold">
                      Pincode
                    </label>
                    <input
                      type="number"
                      name="shipping_pincode"
                      placeholder="Enter Pincode"
                      pattern="[0-9]{6}"
                      maxLength="6"
                      value={formData.shipping_pincode}
                      min="0"
                      onChange={handleChange}
                      className={`w-full p-2 border ${
                        errors.shipping_pincode
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded`}
                    />
                    {errors.shipping_pincode && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.shipping_pincode}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-gray-700 font-semibold">
                      State
                    </label>
                    <select
                      name="shipping_state"
                      value={formData.shipping_state}
                      onChange={handleChange}
                      className={`w-full p-2 border ${
                        errors.shipping_state
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded`}
                    >
                      <option value="">Select State</option>
                      {statesList.map((state, index) => (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                    {errors.shipping_state && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.shipping_state}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-gray-700 font-semibold">
                      Country
                    </label>
                    <input
                      type="text"
                      name="shipping_country"
                      placeholder="Country"
                      value={formData.shipping_country}
                      onChange={handleChange}
                      className={`w-full p-2 border ${
                        errors.country ? "border-red-500" : "border-gray-300"
                      } rounded`}
                      readOnly
                    />
                    {errors.country && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.country}
                      </p>
                    )}
                  </div>
                </div>

                <button
                  type="submit"
                  className="w-full py-3 bg-blue-600 hover:bg-blue-500 text-white font-bold rounded-lg mt-5"
                  disabled={loading}
                >
                  {loading ? "Saving..." : "Save and Deliver here"}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <p className="mb-4 text-lg font-semibold">
              Is this address correct for delivering your item ?
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => handleModalResponse("no")}
                className="px-4 py-2 bg-gray-300 rounded"
              >
                No
              </button>
              <button
                onClick={() => handleModalResponse("yes")}
                className="px-4 py-2 bg-blue-600 text-white rounded"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg w-1/3 p-6">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold">Grand Total</h2>
              <button
                onClick={handleCloseModal}
                className="text-gray-500 hover:text-gray-700"
              >
                &times;
              </button>
            </div>
            <div className="mt-4 text-start">
              <p>Price: ₹{plans.offerPrice}</p>
              <p>Shipping Charge: ₹{shippingCharge}</p>
              <p className="font-bold">Grand Total: ₹{grandTotal}</p>
            </div>
            <div className="mt-6 flex justify-between">
              <button
                onClick={handleCloseModal}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
              >
                Close
              </button>
              <button
                onClick={handleSubmit}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Proceed to Payment
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ShippingAddress;
