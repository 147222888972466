import React, { useState } from "react";
import LOGO from "../../assets/images/logo.png";
import CARD from "../../assets/images/card.jpg";
import { useNavigate, Link } from "react-router-dom";
import "../css/Homepage.css";
import CROSS from "../../assets/images/cross.png";
import CHECK from "../../assets/images/check.png";
import Profile from "./Profile";
import { useUser } from "../../authentication/AuthProvider";

function HowToUse() {
  const [isFullScreen] = useState(false);
  const { isLoggedIn, loginUser } = useUser();
  
  const navigate = useNavigate();

  return (
    <div
      className={`bg-img min-h-screen w-[100%] bg-cover bg-center ${
        isFullScreen ? "fullscreen" : ""
      }`}
    >
      <div className="flex flex-row justify-between md:h-24 h-16 items-center px-4 lg:px-24 md:px-9" style={{ background: "#1b1919" }}>
        <div className="flex justify-center md:justify-start">
            <Link to="/">
          <img src={LOGO} className="h-16 md:h-36" alt="logo" />
          </Link>
        </div>
        <div className="flex flex-row md:gap-24 gap-4 md:pr-26 md:text-xl text-sm">
          {isLoggedIn ? (
            <>
              <Link to="/" className="Poppins text-white cursor-pointer">
                Home
              </Link>
              <Link to="/dashboard" className="Poppins text-white cursor-pointer">
                Dashboard
              </Link>
            <Profile />
            </>
          ) : (
            <>
              <Link to="/" className="Poppins text-white cursor-pointer">
                Home
              </Link>
              <Link to="/plans" className="Poppins text-white cursor-pointer">
                Plans
              </Link>
              <Link to="/login" className="Poppins text-white cursor-pointer">
                Login
              </Link>
              <Link to="/signup" className="Poppins text-white cursor-pointer">
                Signup
              </Link>
            </>
          )}
        </div>
      </div>
      <div className="text-white text-center">
  <p>The standard Lorem Ipsum passage, used since the 1500s</p>
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
  quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
  consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
  cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
  proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
  <p>
    Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45
    BC
  </p>
  "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
  doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
  veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim
  ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia
  consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque
  porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur,
  adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et
  dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis
  nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex
  ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea
  voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem
  eum fugiat quo voluptas nulla pariatur?"
  <p>1914 translation by H. Rackham</p>
  "But I must explain to you how all this mistaken idea of denouncing pleasure
  and praising pain was born and I will give you a complete account of the
  system, and expound the actual teachings of the great explorer of the truth,
  the master-builder of human happiness. No one rejects, dislikes, or avoids
  pleasure itself, because it is pleasure, but because those who do not know how
  to pursue pleasure rationally encounter consequences that are extremely
  painful. Nor again is there anyone who loves or pursues or desires to obtain
  pain of itself, because it is pain, but because occasionally circumstances
  occur in which toil and pain can procure him some great pleasure. To take a
  trivial example, which of us ever undertakes laborious physical exercise,
  except to obtain some advantage from it? But who has any right to find fault
  with a man who chooses to enjoy a pleasure that has no annoying consequences,
  or one who avoids a pain that produces no resultant pleasure?"
  <p>
    Section 1.10.33 of "de Finibus Bonorum et Malorum", written by Cicero in 45
    BC
  </p>
  "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
  praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias
  excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui
  officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum
  quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum
  soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime
  placeat facere possimus, omnis voluptas assumenda est, omnis dolor
  repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum
  necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae
  non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut
  reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus
  asperiores repellat."
    </div>
    </div>
  );
}

export default HowToUse;
